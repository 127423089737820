function greetingsMessage() {
  const { date } = require('quasar');

  let greet = '';
  let tdf = new Date();
  let tdt = new Date();
  if (
    date.isBetweenDates(
      new Date(),
      tdf.setHours(4, 59, 59, 99),
      tdt.setHours(12, 0, 0, 0)
    )
  ) {
    // Good Morning
    greet = this.greetings?.morning;
  } else if (
    date.isBetweenDates(
      new Date(),
      tdf.setHours(11, 59, 59, 99),
      tdt.setHours(16, 59, 59, 99)
    )
  ) {
    // Good Afternoon
    greet = this.greetings?.afternoon;
  } else if (
    date.isBetweenDates(
      new Date(),
      tdf.setHours(16, 59, 59, 99),
      tdt.setHours(23, 59, 59, 99)
    ) ||
    date.isBetweenDates(
      new Date(),
      date.subtractFromDate(tdf.setHours(16, 59, 59, 99), { days: 1 }),
      tdt.setHours(5, 0, 0, 0)
    )
  ) {
    // Good Evening
    greet = this.greetings?.evening;
  }
  return greet || 'Howeday';
}

function countries(state) {
  return state.allCountries.map((e) => e.name);
}

function areaCodes(state) {
  let res = {};
  state.allCountries.forEach((e) => {
    res[e.dialling_code] = {
      name: e.name,
      label: e.code,
      value: e.dialling_code,
    };
  });
  return res;
}

function allCurrency() {
  return this.allCountries.map((e) => e.currency.code);
}

export { greetingsMessage, countries, areaCodes, allCurrency };
