import { mapActions } from 'pinia';
import fnStore from 'src/stores/fn.store';
import useHelpers from 'stores/helpers';
import {
  getFileTypeIcon,
  checkFileExtensions,
} from 'src/assets/scripts/functions';

export default {
  data() {
    return {
      getFileTypeIcon,
      checkFileExtensions,
    };
  },

  methods: {
    ...mapActions(useHelpers, ['deleteFile', 'deleteFolder']),

    addUploadToModel(modelVar, modelId, files, uploaderRef, callback) {
      if (!this[modelVar]) {
        console.error('Model var is missing');
        return;
      }
      if (!this?.[modelVar]?.[modelId]) {
        this[modelVar][modelId] = {
          files: [],
          removeFileById: (id) =>
            this.removeUploadToModel(modelVar, modelId, id, uploaderRef),
        };
      }
      this[modelVar][modelId].files =
        this[modelVar][modelId].files.concat(files);
      if (typeof callback === 'function')
        callback({ modelVar, modelId, files, uploaderRef, callback });
    },

    removeUploadToModel(modelVar, modelId, fileId, uploaderRef) {
      if (!this[modelVar]) {
        console.error('Model var is missing');
        return;
      }
      if (!this?.[modelVar]?.[modelId]?.files?.length) return;
      if (isNaN(fileId))
        fileId = this[modelVar][modelId].files.findIndex(
          (e) => e.__key === fileId
        );
      if (fileId === -1)
        fileId = this[modelVar][modelId].files.findIndex(
          (e) => e.name === fileId
        );
      const modelRef = this[modelVar][modelId];
      const file = modelRef?.files[fileId];
      modelRef?.files.splice?.(fileId, 1);
      if (file) {
        modelRef.removedFile = file;
        this.$refs[uploaderRef]?.removeFile?.(file);
      }
    },

    onRejected(rejectedEntries) {
      rejectedEntries
        .filter((e) => e.failedPropValidation === 'duplicate')
        .forEach((info) => {
          this.$q.notify({
            icon: 'icon-alert-triangle',
            iconColor: 'negative',
            message: `Duplicate entry for ${
              info.file.name || 'selected file'
            }.`,
          });
        });

      const maxFiles = rejectedEntries.filter(
        (e) => e.failedPropValidation === 'max-files'
      );
      if (maxFiles.length) {
        this.$q.notify({
          icon: 'icon-alert-triangle',
          iconColor: 'negative',
          message: `File${
            maxFiles?.length > 1 ? 's' : ''
          } ignored. Already exceeds the maximum number of files allowed!`,
          caption: `Failed: [${maxFiles.map((e) => e.file.name).join(', ')}]`,
        });
      }

      const maxFileSize = rejectedEntries.filter(
        (e) => e.failedPropValidation === 'max-file-size'
      );
      if (maxFileSize.length) {
        this.$q.notify({
          icon: 'icon-alert-triangle',
          iconColor: 'negative',
          message: `File${
            maxFileSize?.length > 1 ? 's' : ''
          } exceeds the maximum file size allowed.`,
          caption: `Failed: [${maxFileSize
            .map((e) => e.file.name)
            .join(', ')}]`,
        });
      }

      const maxTotalSize = rejectedEntries.filter(
        (e) => e.failedPropValidation === 'max-total-size'
      );
      if (maxTotalSize.length) {
        this.$q.notify({
          icon: 'icon-alert-triangle',
          iconColor: 'negative',
          message: `File${
            maxTotalSize?.length > 1 ? 's' : ''
          } ignored. Already exceeds the maximum total file size allowed!`,
          caption: `Failed: [${maxTotalSize
            .map((e) => e.file.name)
            .join(', ')}]`,
        });
      }

      const filtered = rejectedEntries.filter(
        (e) =>
          !/(max-total-size|max-file-size|max-files|duplicate)/.test(
            e.failedPropValidation
          )
      );
      if (filtered.length) {
        this.$q.notify({
          icon: 'icon-alert-triangle',
          iconColor: 'negative',
          message: `File${
            filtered?.length > 1 ? 's' : ''
          } did not pass validation constraints!`,
          caption: `Failed: [${filtered.map((e) => e.file.name).join(', ')}]`,
        });
      }
    },

    async deleteAction(file) {
      if (!file.id && !file.name) return;
      const response = await this[file.folder ? 'deleteFolder' : 'deleteFile'](
        file.id
      );
      if (!response?.success) {
        this.$q.notify({
          icon: 'icon-alert-triangle',
          iconColor: 'negative',
          message: `Error while deleting ${file.name}.`,
        });
        return false;
      }
      return true;
    },

    factoryFnAutoUpload(uploads, url = 'tmp', fieldName = 'uploads[]') {
      return new Promise((resolve, reject) => {
        const token = fnStore.getAuth();
        resolve({
          method: 'POST',
          fieldName: fieldName,
          url: url == 'tmp' ? `${this.$config?.api?.baseURL}/file/temp` : url,
          headers: [{ name: 'Authorization', value: `Bearer ${token}` }],
          withCredentials: false,
        });
      });
    },
  },
};
