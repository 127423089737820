import { reactive } from 'vue';

export default function () {
  return {
    /**
     * Users Geolocation
     */
    geolocation: reactive({}),

    /**
     * Active Organization
     */
    activeOrgID: 0,

    /**
     * User Information
     */
    user: reactive({}),

    /**
     * Organizations
     */
    organizations: reactive({}),

    /**
     * Brands per Organization
     */
    organizationBrands: reactive({}),

    organizationUsers: reactive({}),

    organizationFiles: reactive({}),

    organizationProjects: reactive({}),

    organizationTasks: reactive({}),
  };
}
