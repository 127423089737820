<template>
  <q-card flat class="cursor-pointer full-height text-h6 row items-center">
    <q-item :class="`items-${align} q-pa-${itemPadding}`" class="no-min-height">
      <q-item-section v-if="$slots.avatar" side :class="avatarClass">
        <slot name="avatar" />
      </q-item-section>

      <q-item-section class="text-left">
        <div v-if="$slots.title" :class="titleClass">
          <slot name="title" />
        </div>
        <div v-if="$slots.description" class="text-primary-500">
          <slot name="description" />
        </div>
      </q-item-section>
    </q-item>
  </q-card>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'CardItem',

  props: {
    align: {
      type: String,
      default: 'start',
    },
    itemPadding: {
      type: String,
      default: 'md',
    },
    avatarClass: {
      type: String,
      default: 'q-pr-md',
    },
    titleClass: {
      type: String,
      default: '',
    },
  },
});
</script>
