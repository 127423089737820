import { api } from 'src/boot/apis';
import fnStore from '../fn.store';
import { checkIfObject } from 'src/assets/scripts/functions';

function updateGeoInformation(data, { key, merge = false }) {
  if (merge) {
    this.geolocation = { ...this.geolocation, ...data };
  } else if (key) {
    this.geolocation[key] = data;
  } else {
    this.geolocation = data;
  }
}

function afterLogin(data, token) {
  if (!data) return;
  const user = data.data?.user || data?.user || {};
  const currentUserIsNotVerified = this.currentUserIsNotVerified;
  const onSignup = this.onSignupStep(user.signup_step);
  this.user = user;
  this.user.token = token;
  const organization = data.data?.organization || [];
  for (const org of organization) {
    // Set as Current Active Org
    if (!this.activeOrgID) this.activeOrgID = org.id;
    // Add Org info
    this.organizations[org.id] = org;
    // Add Org brands
    if (typeof this.organizationBrands[org.id] !== 'object')
      this.organizationBrands[org.id] = {};
    // Delete Brands Key
    delete this.organizations[org.id].brands;
  }
  // Validate If User is Ready
  if (this.selectedOrgIsCancelled) {
    this.router.replace({
      path: '/signup',
      query: {
        email: user.email,
        status: 'subscription_cancelled',
      },
    });
    fnStore.removeAuth();
    if (process.env.DEV_MODE) console.log('Account is Cancelled');
  } else if (this.subscriptionIsExpired()) {
    this.router.replace({
      path: '/signup',
      query: {
        email: user.email,
        status: 'subscription_expired',
      },
    });
    fnStore.removeAuth();
    if (process.env.DEV_MODE) console.log('Subscription is Expired');
  } else if (onSignup) {
    const query = {
      verify_email: user.email,
    };
    if (onSignup && !isNaN(onSignup)) {
      query.step = onSignup;
    } else if (onSignup === 'verify-email') {
      query.status = 'unverified_email';
    }
    this.router.replace({
      path: '/signup',
      query,
    });
    fnStore.removeAuth();
    if (process.env.DEV_MODE) console.log('Singup Incomplete. Step:', onSignup);
  } else if (this.selectedOrgAccountPending) {
    this.router.replace({
      path: '/signup',
      query: {
        email: user.email,
        status: 'subscription_account_pending',
      },
    });
    fnStore.removeAuth();
    if (process.env.DEV_MODE) console.log('Account is Pending');
  } else if (currentUserIsNotVerified) {
    this.router.replace({
      path: '/signup',
      query: {
        email: user.email,
        status: 'email_not_verified',
      },
    });
    fnStore.removeAuth();
    if (process.env.DEV_MODE) console.log('Email is Not Verified');
  } else {
    // Add Auth if User has Verified Email
    if (token) fnStore.addAuth(token);
  }
}

function isEmailVerified(response) {
  const user = response.data?.user || {};
  if (
    user.email_verified_at
    // && this.onSignupStep(user.signup_step) === 'verify-email'
  ) {
    return true;
  } else return false;
}

function onSignupStep(signup_step) {
  if (signup_step === 'step_2') {
    return 2;
  } else if (signup_step === 'step_3') {
    return 3;
  } else if (signup_step === 'verify-email') {
    return 'verify-email';
  } else return 0;
}

// Start of Signup
async function signupStep1(payload) {
  return await api
    .post('/signup', payload)
    .then((res) => {
      const data = res.data;
      let token = data.data?.token;
      if (data?.success && token) this.afterLogin({ user: data.data }, token);
      return data;
    })
    .catch((e) => {
      return e;
    });
}

async function signupStep2(payload, token) {
  return await api
    .post('/signup/step2', payload, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((res) => {
      const data = res.data;
      if (data?.success) this.afterLogin(data, token);
      return data;
    })
    .catch((e) => {
      return e;
    });
}

async function signupStep3(payload, token) {
  return await api
    .post('/signup/step3', payload, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((res) => {
      const data = res.data;
      if (data?.success) this.afterLogin(data, token);
      return data;
    })
    .catch((e) => {
      return e;
    });
}

async function signupVerifyEmail(signup_token) {
  return await api
    .post('/signup/verify-email', {
      signup_token,
    })
    .then((res) => {
      const data = res.data;
      let token = data.data?.token;
      if (data?.success && token) this.afterLogin(data, token);
      return data;
    })
    .catch((e) => {
      return e;
    });
}

async function signupResendEmailVerify(email) {
  return await api
    .post('/signup/resend-email-verify', {
      email,
    })
    .then((res) => {
      const data = res.data;
      return data;
    })
    .catch((e) => {
      return e;
    });
}
// End of Signup

async function loginWithPassword(payload) {
  return await api
    .post('/login', payload)
    .then((res) => {
      const data = res.data;
      let token = data?.data?.token;
      if (data?.success && token) this.afterLogin(data, token);
      return data;
    })
    .catch((e) => {
      return e;
    });
}

async function loginWithToken(custom_token) {
  if (!custom_token) custom_token = fnStore.getAuth();
  const options = {
    headers: {
      Authorization: custom_token,
    },
  };
  const url = '/me';
  return await api
    .get(url, options)
    .then((res) => {
      const data = res.data;
      let token = custom_token ? custom_token : fnStore.getAuth();
      if (data?.success) this.afterLogin(data, token);
      fnStore.addAPICache(url, data);
      return data;
    })
    .catch((e) => {
      const cache_data = fnStore.getAPICache(url);
      let token = custom_token ? custom_token : fnStore.getAuth();
      if (cache_data?.success) this.afterLogin(cache_data, token);
      return cache_data || e;
    });
}

async function loginWithPaymentToken(payment_token) {
  const url = '/login-token';
  return await api
    .post(url, {
      payment_token,
    })
    .then((res) => {
      const data = res.data;
      let token = data?.data?.token;
      if (data?.success && token) this.afterLogin(data, token);
      return data;
    })
    .catch((e) => {
      return e;
    });
}

async function logout(path = '/auth') {
  return await api
    .post('/logout')
    .then((res) => {
      const data = res.data;
      if (data?.success) {
        fnStore.removeAuth();
        window.location.href = path;
      }
      return data;
    })
    .catch((e) => {
      return e;
    });
}

async function updateAccount(payload) {
  return await api
    .post('/account/edit', payload)
    .then((res) => {
      const data = res.data;
      let userInfo = data.data;
      if (checkIfObject(userInfo)) {
        Object.keys(userInfo).forEach((k) => {
          this.user[k] = userInfo[k];
        });
      }
      return data;
    })
    .catch((e) => {
      return e;
    });
}

async function updatePassword(payload) {
  return await api
    .post('/update-password', payload)
    .then((res) => {
      const data = res.data;
      return data;
    })
    .catch((e) => {
      return e;
    });
}

// Invitation
async function resendEmailInvitation(email) {
  return await api
    .post(
      '/organization/users/resend-invitation',
      {
        email,
      },
      { headers: { Authorization: `Bearer ${this.user.token}` } }
    )
    .then((res) => {
      const data = res.data;
      return data;
    })
    .catch((e) => {
      return e;
    });
}

async function acceptInvitation(invite_token) {
  return await api
    .post('/accept-invitation', {
      invite_token,
    })
    .then((res) => {
      const data = res.data;
      let token = data.data?.token;
      if (data?.success && token) this.afterLogin(data, token);
      return data;
    })
    .catch((e) => {
      return e;
    });
}

// Onboarding
async function onboardUser(payload) {
  return await api
    .post(`/onboarding`, payload)
    .then((res) => {
      const data = res.data;
      if (data.success && data.data?.user) this.user = data.data?.user;
      return data;
    })
    .catch((e) => {
      return e;
    });
}

// User Roles
function getUserRole(userId = this.user?.id, orgId = this.activeOrgID) {
  if (!orgId) orgId = this.activeOrgID;
  return (
    this.organizationUsers[orgId]?.[userId]?.role ||
    this.organizationUsers[orgId]?.[userId]?.organization_role ||
    this.user?.role ||
    this.user?.organization_role
  );
}

function isOrgOwner(userId = this.user?.id, orgId = this.activeOrgID) {
  if (!orgId) orgId = this.activeOrgID;
  return userId === this.organizations[orgId]?.['owner_id'];
}

function isOrgAdmin(userId = this.user?.id, orgId = this.activeOrgID) {
  if (!orgId) orgId = this.activeOrgID;
  return this.getUserRole(userId, orgId) === 'organization_admin';
}

function isOrgBiller(userId = this.user?.id, orgId = this.activeOrgID) {
  if (!orgId) orgId = this.activeOrgID;
  return this.getUserRole(userId, orgId) === 'organization_billing';
}

function isOrgEmployee(userId = this.user?.id, orgId = this.activeOrgID) {
  if (!orgId) orgId = this.activeOrgID;
  return this.getUserRole(userId, orgId) === 'organization_employee';
}
// End of User Roles

async function updateOrganization(payload = {}, orgId = this.activeOrgID) {
  if (!orgId) orgId = this.activeOrgID;
  return await api
    .post(`/organization/${orgId}/edit`, payload)
    .then((res) => {
      const data = res.data;
      const orgInfo = data.data;
      if (checkIfObject(orgInfo)) {
        Object.keys(orgInfo).forEach((k) => {
          this.organizations[orgId][k] = orgInfo[k];
        });
      }
      return data;
    })
    .catch((e) => {
      return e;
    });
}

function searchUserInfo(info, allResult = false) {
  let userInfo = Object.values(this.organizationUsers).filter((usr) => {
    let res = false;
    Object.keys(usr).forEach((e) => {
      if (usr[e] == info) res = true;
    });
    return res;
  });
  return allResult ? userInfo : userInfo[0] || {};
}

// Organization User
async function getOrgUsers(org_id = this.activeOrgID) {
  const url = `/organization/${org_id}/users`;
  return await api
    .get(url)
    .then((res) => {
      const data = res.data;
      if (data.success) {
        this.organizationUsers[org_id] = {};
        for (const usr of data.data) {
          this.organizationUsers[org_id][usr.id] = usr;
        }
      }
      fnStore.addAPICache(url, data);
      return data;
    })
    .catch((e) => {
      const cache_data = fnStore.getAPICache(url);
      if (cache_data.success) {
        this.organizationUsers[org_id] = {};
        for (const usr of cache_data.data) {
          this.organizationUsers[org_id][usr.id] = usr;
        }
      }
      return cache_data || e;
    });
}

async function addOrgUser(userInfo = {}, orgId = this.activeOrgID) {
  if (!orgId) orgId = this.activeOrgID;
  userInfo.org_id = [orgId];
  return await api
    .post(`/organization/users`, userInfo)
    .then((res) => {
      const data = res.data;
      if (data.success) {
        const uid = data.data?.user?.id;
        this.organizationUsers[orgId][uid] = data.data?.user;
      }
      return data;
    })
    .catch((e) => {
      return e;
    });
}

async function updateOrgUser(userId, userInfo = {}, orgId = this.activeOrgID) {
  if (!orgId) orgId = this.activeOrgID;
  if (userId === null || userId === undefined) return {};
  return await api
    .post(`/organization/users/${userId}`, userInfo)
    .then((res) => {
      const data = res.data;
      if (data.success) {
        this.organizationUsers[orgId][userId] = data?.data?.user || {};
      }
      return data;
    })
    .catch((e) => {
      return e;
    });
}

async function deleteOrgUser(userId, orgId = this.activeOrgID) {
  if (!orgId) orgId = this.activeOrgID;
  if (userId === null || userId === undefined) return {};
  return await api
    .delete(`/organization/users/${userId}`)
    .then((res) => {
      const data = res.data;
      if (data.success) {
        delete this.organizationUsers[orgId][userId];
      }
      return data;
    })
    .catch((e) => {
      return e;
    });
}

// Organization Brands
async function createBrand(payload, orgId = this.activeOrgID) {
  if (!orgId) orgId = this.activeOrgID;
  return await api
    .post(`/organization/${orgId}/brands`, payload)
    .then((res) => {
      const data = res.data;
      if (data.success) this.addOrgBrand(data.data?.id, data.data, orgId);
      return data;
    })
    .catch((e) => {
      return e;
    });
}

async function updateBrand(payload, brandId, orgId = this.activeOrgID) {
  if (!orgId) orgId = this.activeOrgID;
  return await api
    .put(`/organization/${orgId}/brands/${brandId}/update`, payload)
    .then((res) => {
      const data = res.data;
      if (data.success) this.updateOrgBrand(brandId, data.data, {}, orgId);
      return data;
    })
    .catch((e) => {
      return e;
    });
}

async function updateBrandGoogleFonts(
  payload,
  brandId,
  orgId = this.activeOrgID
) {
  if (!orgId) orgId = this.activeOrgID;
  return await api
    .put(`/organization/${orgId}/brands/${brandId}/googlefonts`, payload)
    .then((res) => {
      const data = res.data;
      if (data.success) this.updateOrgBrand(brandId, data.data, {}, orgId);
      return data;
    })
    .catch((e) => {
      return e;
    });
}

async function updateBrandSocial(payload, brandId, orgId = this.activeOrgID) {
  return await api
    .put(`/organization/${orgId}/brands/${brandId}/social-account`, payload)
    .then((res) => {
      const data = res.data;
      if (data.success) this.updateOrgBrand(brandId, data.data, {}, orgId);
      return data;
    })
    .catch((e) => {
      return e;
    });
}

async function updateBrandFiles(
  uploads,
  target_path,
  brandId,
  orgId = this.activeOrgID
) {
  if (!orgId) orgId = this.activeOrgID;
  const formData = new FormData();
  if (uploads && uploads.length) {
    for (const [i, f] of uploads.entries()) {
      formData.append(`uploads[${i}]`, f);
    }
  }
  formData.append('target_path', target_path);
  return await api
    .post(`/organization/${orgId}/brands/${brandId}/upload`, formData, {
      'Content-Type': 'multipart/form-data',
    })
    .then((res) => {
      const data = res.data;
      if (data.success) this.updateOrgBrand(brandId, data.data, {}, orgId);
      return data;
    })
    .catch((e) => {
      return e;
    });
}

async function getSingleBrand(brandId, orgId = this.activeOrgID) {
  if (!orgId) orgId = this.activeOrgID;
  if (!brandId || !orgId) return;
  const url = `/organization/${orgId}/brands/${brandId}`;
  return await api
    .get(url)
    .then((res) => {
      const data = res.data;
      if (data.success) {
        const brand = data.data;
        if (brand.id && !brand.deleted_at)
          this.organizationBrands[orgId][brand.id] = brand;
        fnStore.addAPICache(url, data);
        return data;
      }
    })
    .catch((e) => {
      const cache_data = fnStore.getAPICache(url);
      const brands_cache = cache_data.data?.data || [];
      for (const brand of brands_cache) {
        this.organizationBrands[orgId][brand.id] = brand;
      }
      return cache_data || e;
    });
}

async function getOrgBrands(
  saveState = true,
  orgId = this.activeOrgID,
  params = {}
) {
  if (!orgId) orgId = this.activeOrgID;
  const url = `/organization/${orgId}/brands`;
  if (params && !params.per_page) params.per_page = -1;
  return await api
    .get(url, {
      params,
    })
    .then((res) => {
      const data = res?.data;
      if (data.success && saveState) {
        const brands = data.data?.data || [];
        for (const brand of brands) {
          this.organizationBrands[orgId][brand.id] = brand;
        }
      }
      fnStore.addAPICache(url, data);
      return data;
    })
    .catch((e) => {
      const cache_data = fnStore.getAPICache(url);
      const brands_cache = cache_data.data?.data || [];
      for (const brand of brands_cache) {
        this.organizationBrands[orgId][brand.id] = brand;
      }
      return cache_data || e;
    });
}

function removeBrandFile(brandId, file, target_path, orgId = this.activeOrgID) {
  if (!brandId || !file || !orgId) return;
  const uploadedFiles =
    this.organizationBrands?.[orgId]?.[brandId]?.[target_path];
  if (uploadedFiles) {
    const fileIndex = uploadedFiles.findIndex((e) =>
      file.isSkeleton
        ? e.isSkeleton && e.file_info?.__key === file.file_info?.__key
        : e.id === file.id
    );
    if (fileIndex !== -1) uploadedFiles?.splice?.(fileIndex, 1);
  }
}

async function updateBrandColors(payload, brandId, orgId = this.activeOrgID) {
  if (!orgId) orgId = this.activeOrgID;
  if (!orgId || !brandId || !orgId) return {};
  return await api
    .put(`/organization/${orgId}/brands/${brandId}/color`, payload)
    .then((res) => {
      const data = res.data;
      if (data.success) this.updateOrgBrand(brandId, data.data, {}, orgId);
      return data;
    })
    .catch((e) => {
      return e;
    });
}

function addOrgBrand(brandId, brandInfo = {}, orgId = this.activeOrgID) {
  if (!brandId || !orgId) return {};
  this.organizationBrands[orgId][brandId] = brandInfo;
}

function updateOrgBrand(
  brandId,
  brandInfo = {},
  { key, merge = false },
  orgId = this.activeOrgID
) {
  if (!orgId) orgId = this.activeOrgID;
  if (!brandId || !orgId) return {};
  if (!this.organizationBrands?.[orgId]?.[brandId])
    this.organizationBrands[orgId][brandId] = {};
  if (merge) {
    this.organizationBrands[orgId][brandId] = {
      ...this.organizationBrands[orgId][brandId],
      ...brandInfo,
    };
  } else if (key) {
    this.organizationBrands[orgId][brandId][key] = brandInfo;
  } else {
    this.organizationBrands[orgId][brandId] = brandInfo;
  }
}

function removeFromOrgBrandState(brandId, orgId = this.activeOrgID) {
  if (!orgId) orgId = this.activeOrgID;
  if (!brandId || !orgId) return {};
  if (this.organizationBrands[orgId][brandId])
    delete this.organizationBrands[orgId][brandId];
}

async function archiveOrgBrand(brandId, orgId = this.activeOrgID) {
  if (!orgId) orgId = this.activeOrgID;
  if (!brandId || !orgId) return {};
  return await api
    .put(`/organization/${orgId}/brands/${brandId}/archive`)
    .then((res) => {
      const data = res.data;
      if (data.success) {
        if (this.organizationBrands[orgId][brandId])
          this.organizationBrands[orgId][brandId].deleted_at = Date.now();
        this.removeFromOrgBrandState(brandId, orgId);
      }
      return data;
    })
    .catch((e) => {
      return e;
    });
}

async function restoreArchiveOrgBrand(brandInfo, orgId = this.activeOrgID) {
  if (!orgId) orgId = this.activeOrgID;
  if (!brandInfo?.id || !orgId) return {};
  const brandId = brandInfo.id;
  return await api
    .put(`/organization/${orgId}/brands/${brandId}/restore`)
    .then((res) => {
      const data = res.data;
      if (data.success) {
        this.organizationBrands[orgId][brandId] = brandInfo;
        if (this.organizationBrands[orgId][brandId])
          this.organizationBrands[orgId][brandId].deleted_at = null;
      }
      return data;
    })
    .catch((e) => {
      return e;
    });
}

async function deleteOrgBrand(brandId, orgId = this.activeOrgID) {
  if (!orgId) orgId = this.activeOrgID;
  if (!brandId || !orgId) return {};
  return await api
    .delete(`/organization/${orgId}/brands/${brandId}`)
    .then((res) => {
      const data = res.data;
      if (data.success) {
        this.removeFromOrgBrandState(brandId, orgId);
      }
      return data;
    })
    .catch((e) => {
      return e;
    });
}

async function updateBrandAvatar(fileId, brandId, orgId = this.activeOrgID) {
  if (!orgId) orgId = this.activeOrgID;
  if (!brandId || !orgId) return {};
  return await api
    .put(`/organization/${orgId}/brands/${brandId}/avatar`, { avatar: fileId })
    .then((res) => {
      const data = res.data;
      if (data.success)
        this.updateOrgBrand(
          brandId,
          data.data?.avatar || {},
          { key: 'avatar' },
          orgId
        );
      return data;
    })
    .catch((e) => {
      return e;
    });
}

// Subscription
function subscriptionRemaining(date_now = new Date(), unit = 'days') {
  const { date } = require('quasar');
  if (this.subscriptionDaysLength === 0) return 0;
  const subscription_end = this.selectedOrg?.subscriptions?.subscription_end;
  if (new Date(date_now) > new Date(subscription_end)) return 0;
  return date.getDateDiff(subscription_end, date_now, unit);
}

function subscriptionIsExpired(date_now = new Date()) {
  return !this.activeOrgID ||
    !this.user.id ||
    !this.selectedOrgSubscription?.status
    ? false
    : Number(this.subscriptionRemaining(date_now, 'seconds')) < 1;
}

async function switchToMaintenancePlan(orgId = this.activeOrgID) {
  if (!orgId) orgId = this.activeOrgID;
  return await api
    .post('/change-plan/maintenance', { org_id: orgId })
    .then((res) => {
      const data = res.data;
      if (data?.success) {
        if (this.organizations?.[orgId]?.subscriptions) {
          // Switch to Maintenance Plan
          this.organizations[orgId].subscriptions.is_maintenance = true;
        }
      }
      return data;
    })
    .catch((e) => {
      return e;
    });
}

async function requestSubscriptionChange(payload, orgId = this.activeOrgID) {
  if (!orgId) orgId = this.activeOrgID;
  return await api
    .post('/change-plan/request', { org_id: orgId, ...payload })
    .then((res) => {
      const data = res.data;
      return data;
    })
    .catch((e) => {
      return e;
    });
}

async function pauseSubscription(payload, orgId = this.activeOrgID) {
  if (!orgId) orgId = this.activeOrgID;
  return await api
    .post('/change-plan/pause', { org_id: orgId, ...payload })
    .then((res) => {
      const data = res.data;
      if (data?.success) {
        if (this.organizations?.[orgId]?.subscriptions)
          this.organizations[orgId].subscriptions = data.data;
      }
      return data;
    })
    .catch((e) => {
      return e;
    });
}

async function resumeSubscription(payload, orgId = this.activeOrgID) {
  if (!orgId) orgId = this.activeOrgID;
  return await api
    .post('/change-plan/resume', { org_id: orgId, ...payload })
    .then((res) => {
      const data = res.data;
      if (data?.success) {
        if (this.organizations?.[orgId]?.subscriptions)
          this.organizations[orgId].subscriptions = data.data;
      }
      return data;
    })
    .catch((e) => {
      return e;
    });
}

async function cancelSubscription(payload, orgId = this.activeOrgID) {
  if (!orgId) orgId = this.activeOrgID;
  return await api
    .post('/offboarding', { org_id: orgId, ...payload })
    .then((res) => {
      const data = res.data;
      if (data.success) this.afterLogin(data);
      return data;
    })
    .catch((e) => {
      return e;
    });
}

async function getPusherAuth(payload) {
  if (!orgId) orgId = this.activeOrgID;
  return await api
    .post('/pusher/auth', payload)
    .then((res) => {
      const data = res.data;
      return data;
    })
    .catch((e) => {
      return e;
    });
}

export {
  updateGeoInformation,
  signupStep1,
  signupStep2,
  signupStep3,
  signupVerifyEmail,
  signupResendEmailVerify,
  onSignupStep,
  afterLogin,
  isEmailVerified,
  loginWithPassword,
  loginWithToken,
  loginWithPaymentToken,
  updateAccount,
  updatePassword,
  resendEmailInvitation,
  acceptInvitation,
  onboardUser,
  getUserRole,
  isOrgOwner,
  isOrgAdmin,
  isOrgBiller,
  isOrgEmployee,
  searchUserInfo,
  updateOrganization,
  getOrgUsers,
  addOrgUser,
  updateOrgUser,
  deleteOrgUser,
  logout,
  // Brands
  addOrgBrand,
  updateOrgBrand,
  removeFromOrgBrandState,
  deleteOrgBrand,
  archiveOrgBrand,
  restoreArchiveOrgBrand,
  removeBrandFile,
  updateBrandAvatar,
  updateBrandGoogleFonts,
  // Subscription
  subscriptionRemaining,
  subscriptionIsExpired,
  switchToMaintenancePlan,
  requestSubscriptionChange,
  pauseSubscription,
  resumeSubscription,
  cancelSubscription,
  // Pusher
  getPusherAuth,
  // Brand
  createBrand,
  updateBrand,
  updateBrandSocial,
  updateBrandColors,
  updateBrandFiles,
  getOrgBrands,
  getSingleBrand,
};
