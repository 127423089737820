import { cleanMarkup, objectCopy } from 'src/assets/scripts/functions';

export default {
  methods: {
    formatDynamicAnswersPayload(payload) {
      if (!payload?.dynamic_questions) return payload;
      const dynamic_questions = objectCopy(payload.dynamic_questions);
      for (const id of Object.keys(dynamic_questions)) {
        let val1 = dynamic_questions[id].value;
        let val2 = dynamic_questions[id].alternative_answer;

        dynamic_questions[id].value = this.requestOptionParseOrCleanUp(val1);
        dynamic_questions[id].alternative_answer =
          this.requestOptionParseOrCleanUp(val2);
      }
      return { ...payload, dynamic_questions };
    },

    requestOptionParseOrCleanUpString(val) {
      var res = val;
      if (typeof val === 'string') {
        try {
          res = JSON.parse(val);
        } catch (e) {
          res = cleanMarkup(val);
        }
      }
      return res;
    },

    requestOptionParseOrCleanUp(val) {
      if (!Array.isArray(val))
        return this.requestOptionParseOrCleanUpString(val);
      let model = [];
      for (const v of val) {
        model.push(this.requestOptionParseOrCleanUpString(v));
      }
      return model;
    },
  },
};
