import config from 'src/assets/_config';
import ColorThief from 'src/assets/scripts/min/color-thief.min.mjs';
import { Buffer } from 'buffer';
import { date } from 'quasar';
import { formatDistance } from 'date-fns';
import { toWords } from 'number-to-words';

/**
 * Greetings
 */
const greetings = {
  /**
   * Console Grettings
   */
  console: {
    /**
     * Greetings Console
     */
    dev: () => {
      console.log(
        '%c' + config.default?.message?.welcome,
        'color: grey; font-family:system-ui; font-size: 3rem; font-weight: bold'
      );
      console.log(
        '%c' + config.default?.message?.bug,
        'color: silver; font-size: 1.5em; text-weight: bold;'
      );
    },
    prod: () => {
      console.log('%c' + config.default?.message?.prod, 'color: silver');
    },
  },
};

/**
 * Encode string to base64
 * @param {*} text text encode to `base64`
 */
function base64_encode(text) {
  return Buffer.from(String(text), 'utf-8').toString('base64');
}

/**
 * Decode base64 string
 * @param {String} base64 base64 string to decode
 */
function base64_decode(base64) {
  return Buffer.from(String(base64), 'base64').toString('utf-8');
}

/**
 * Base64 Encode and Decode Function
 */
const base64 = {
  encode: base64_encode,
  decode: base64_decode,
};

const url2link = (url, validNonHttp = ['tel:', 'mailto:']) => {
  if (!url) return '';
  url = String(url).trim();
  let isValidNonHTP = validNonHttp.filter((e) => url.startsWith(e));
  if (isValidNonHTP?.length > 0) return url;
  return config.regexp?.https?.test?.(url) ? url : 'https://' + url;
};

/**
 * Check if `obj` is Object `{}`
 * @param {*} obj object to check if object `{}`
 */
function checkIfObject(obj) {
  return Object.prototype.toString.call(obj) === '[object Object]';
}

/**
 * Check if `obj` is Object `[]`
 * @param {*} obj object to check if array `[]`
 */
function checkIfArray(obj) {
  return Object.prototype.toString.call(obj) === '[object Array]';
}

/**
 * Compare two object keys value if match
 *
 * @param {{}} obj1 First object to compare
 * @param {{}} obj2 Second object to compare
 */
function objectCompare(obj1, obj2) {
  let response = 0;
  if (obj1 && obj2)
    Object.keys(obj1).forEach((e) => {
      if (obj1[e] !== obj2[e]) response += 1;
    });
  return response;
}

/**
 * Copy existing object
 *
 * @param {{}} obj Object to copy
 */
function objectCopy(obj) {
  return JSON.parse(JSON.stringify(obj));
}

/**
 * Validate Input Rules
 * @param {*} toResolve to resolve
 * @param {number} timeout timeout delay `ms`
 * @param {Boolean} forceNoError force to validate no error
 */
function inputValidate(toResolve, timeout = 1000, forceNoError = false) {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      if (checkIfObject(toResolve)) {
        if (forceNoError && !toResolve.value) resolve(true);
        else if (toResolve.type && typeof toResolve.value !== undefined) {
          const val = String(toResolve.value);
          switch (toResolve.type.toLowerCase()) {
            case 'url':
              if (toResolve.withregex) {
                // test by regexp
                resolve(
                  config.regexp?.url?.test?.(val) ||
                    (toResolve.nomsg ? '' : 'Invalid URL')
                );
              } else {
                // default
                // test by try catch with new URL
                let isurl = false;
                try {
                  isurl = new URL(val);
                  isurl = true;
                } catch (e) {
                  isurl = false;
                }
                resolve(isurl || (toResolve.nomsg ? '' : 'Invalid URL'));
              }
              break;

            case 'email':
              resolve(
                config.regexp?.email.test(val.toLowerCase()) ||
                  (toResolve.nomsg ? '' : 'Invalid Email Address')
              );
              break;

            case 'username':
              resolve(
                config.regexp?.username.test(val) ||
                  (toResolve.nomsg ? '' : 'Invalid Username')
              );
              break;

            case 'password':
              resolve(
                !config.regexp?.password.test(val) ||
                  (toResolve.nomsg ? '' : 'Password is Weak')
              );
              break;

            default:
              resolve('');
              break;
          }
        } else {
          resolve('');
        }
      }
      resolve(toResolve);
    }, timeout);
  });
}

/**
 * All Checker
 */
const checker = {
  type: {
    isObject: checkIfObject,
    isArray: checkIfArray,
  },
  input: inputValidate,
};

/**
 * Number to money format with comma and decimal limiter
 * @param {number | string} num to format
 * @param {{style: 'decimal', minimumFractionDigits: number}?} params format options
 */
function formatMoney(
  num,
  params = { style: 'decimal', minimumFractionDigits: 2 }
) {
  return isNaN(num)
    ? 0
    : Number(Number(num).toFixed(params.minimumFractionDigits)).toLocaleString(
        'en-US',
        params
      );
}

/**
 *
 * @param {*} dateValue date value to format
 * @param {{short: String?, long: string?}?} format string date format
 */
function relativeDate(dateValue, format) {
  dateValue = date.isValid(dateValue) ? new Date(dateValue) : false;
  if (dateValue === false) return 'Invalid Date';
  if (date.getDateDiff(new Date(), new Date(dateValue), 'years') == 0) {
    if (date.getDateDiff(new Date(), new Date(dateValue), 'hours') > 12) {
      return format
        ? `${date.formatDate(dateValue, format.short || format)}`
        : `${date.formatDate(dateValue, 'MMMM D')} at ${date.formatDate(
            dateValue,
            'h:mm A'
          )}`;
    } else {
      let response = formatDistance(dateValue, new Date(), {
        addSuffix: true,
      });
      if (String(response).toLocaleLowerCase() == 'less than a minute ago')
        response = 'just now';
      return response;
    }
  } else {
    return date.formatDate(
      dateValue,
      format ? format.long || format : 'MMMM D, YYYY'
    );
  }
}

/**
 * @param {Object[]} ObjArray Array Object to filter
 * @param {String} filter_key Key to filter
 */
function filterUniqueKey(ObjArray, filter_key = null) {
  return ObjArray.filter((obj, index, arr) => {
    return checkIfObject(obj) && filter_key
      ? arr.map((mapObj) => mapObj[filter_key]).indexOf(obj[filter_key]) ===
          index
      : arr.map((mapObj) => mapObj).indexOf(obj) === index;
  });
}

/**
 *
 * @param {setInterval} interval interval to `clear`
 */
async function tryClearInterval(interval, addLog = false) {
  try {
    clearInterval(interval);
  } catch (e) {
    if (addLog) console.warn('Interval Error:', e);
  }
}

/**
 *
 * Search content for specified key inside an object
 * @param {Array} array_obj array of object to search
 * @param {String} searchKeyword keywork to match
 * @param {Array} searchKeys list ok `keys` to search
 */
function searchInObjectArray(
  array_obj = [],
  searchKeyword,
  searchKeys = ['label', 'title', 'description', 'keywords'],
  exactMatch = false
) {
  if (!array_obj || !array_obj.length) return [];
  if (searchKeyword && typeof searchKeyword === 'string')
    searchKeyword = searchKeyword.trim();
  let a = array_obj.filter((op) => {
    // Search Filtering
    let res = false;
    // Search on defined keys
    searchKeys.forEach((sk) => {
      if (res) return;
      let cond = exactMatch
        ? String(op[sk]) === searchKeyword
        : String(op[sk])?.toLowerCase()?.includes(searchKeyword?.toLowerCase());
      res = op[sk] ? cond : false;
    });
    return res;
  });
  return a;
}

/**
 * Clean string limiter
 *
 * @param {String | Number} data        Data to clean and limit
 * @param {Number} limit_length         Length of the value to return
 * @param {String | Number} pad_value   Value of padding
 * @param {Boolean} pad_start           Use .padStart if `true` and .padEnd if `false`. Default is `true`
 * @param {Boolean} trim                To trim value using `String.trim()`
 *
 * @return {String}                     Clean Limited String Value
 */
function cleanPadLimiter(
  data,
  limit_length,
  pad_value = ' ',
  pad_start = true,
  trim = true
) {
  if (typeof data === 'undefined') return data;
  if (isNaN(limit_length)) limit_length = data.length;
  if (typeof pad_start !== 'boolean') pad_start = false;
  if (typeof pad_value === 'undefined') pad_value = ' ';
  if (typeof trim !== 'boolean') trim = false;
  let n = String(data);
  if (trim) n = n.trim();
  n = n.substring(0, limit_length);
  pad_start
    ? (n = n.padStart(limit_length, pad_value))
    : (n = n.padEnd(limit_length, pad_value));
  return n;
}

/**
 * Change focus of input inside a form during keyboard event e.g. `@keydown` or `@keyup`
 * @param {KeyboardEvent} evt Keyboard Event
 * @param {Boolean} next true to focus `next` input or false to focus `previous` input
 * @param {String} form_active_input_class inout class to include on focus
 */
async function changeInputFocusOnKey(
  evt,
  next = true,
  form_active_input_class = 'form_active_input'
) {
  const inputs = Array.from([
    ...evt.target.form.querySelectorAll(
      `input.${form_active_input_class},textarea.${form_active_input_class}`
    ),
  ]);
  const index = inputs.indexOf(evt.target);
  const next_index =
    next === true
      ? index + 1
      : next === false
      ? index - 1
      : isNaN(next)
      ? 0
      : next;
  if (next_index < inputs.length && inputs[next_index]) {
    inputs[next_index].focus();
    return true;
  }
  return false;
}

/**
 * Modify Enter Key for Inputs
 * @param {KeyboardEvent} evt event object
 */
async function defaultModKey(evt, submitCallback) {
  switch (evt.key) {
    case 'Enter': // Handle Enter
      evt.preventDefault();
      if (evt.ctrlKey && evt.shiftKey && evt.altKey) {
        // Control Alt Delete
      } else if (evt.altKey) {
        // Alt
      } else if (evt.shiftKey) {
        // Shift
        changeInputFocusOnKey(evt, false);
      } else if (evt.ctrlKey) {
        // Constrol
        submitCallback?.();
      } else {
        // Enter only
        const focusChanged = await changeInputFocusOnKey(evt);
        if (!focusChanged) submitCallback?.();
      }
      break;

    default:
      break;
  }
}

/**
 * Returns an image element from base64 image url
 * @param {String} b64str base64 image string
 * @returns Promise<HTMLImageElement>
 */
async function getImageFromBase64str(b64str) {
  const img = document.createElement('img');
  await new Promise((r) => {
    img.src = b64str;
    img.onload = r;
  });
  return img;
}

/**
 * Returns the base64 image from a blob url
 * @param {String} url image url
 * @param {Array} filterImgType list of accepted image file type
 */
async function getImageStringFromURL(
  url,
  filterImgType = [
    'image/x-icon',
    'image/jpeg',
    'image/png',
    'image/svg+xml',
    'image/gif',
  ]
) {
  const { axios } = require('src/boot/apis');
  return await axios
    .get(url, {
      responseType: 'arraybuffer',
    })
    .then((res) => {
      let imgType = res?.headers?.['content-type'];
      if (
        res?.status === 200 &&
        (filterImgType === false ||
          (filterImgType && filterImgType.includes?.(imgType)))
      ) {
        let encodedImage = Buffer.from(res.data, 'binary').toString('base64');
        return `data:${imgType};base64, ${encodedImage}`;
      } else {
        console.error(`Error converting`, url);
        return '';
      }
    })
    .catch((e) => {
      return '';
    });
}

/**
 * Generate avatar display for user
 * @param {String} nameString the user full name
 * @param {Boolean} useTwoLetters to use the first letters for the first two words
 * @returns {String | Char}
 */
function avatarNameAbbr(nameString, useTwoLetters = false) {
  const nameSplit = nameString.split(' ');
  return (
    (nameSplit?.[0]?.charAt(0) || '') +
    ((useTwoLetters ? nameSplit?.[1]?.charAt(0) : '') || '')
  ).toUpperCase();
}

/**
 *
 * @param {Number} num number to convert to words
 * @returns {String} words value from the numbers
 */
function numberToWords(num) {
  return isNaN(num) ? NaN : toWords(num);
}

/**
 *
 * @param {String | Object} msg string or Object that has a message value
 * @returns {String} the extracted message
 */
function extractErrorMessageString(msg) {
  if (!msg) return '';
  let message;
  if (typeof msg === 'string') message = msg;
  if (!message && checkIfObject(msg)) {
    let tmp_msg = Object.values(msg);
    if (checkIfObject(tmp_msg)) tmp_msg = Object.values(tmp_msg);
    if (Array.isArray(tmp_msg)) tmp_msg = tmp_msg[0];
    message = typeof tmp_msg === 'string' ? tmp_msg : tmp_msg[0];
  }
  return message;
}

/**
 *
 * @param {String} filename filenme with file extension
 * @param {Array} allowedExt array of allowed file extensions (e.g.: png, jpg, svg)
 * @returns {Boolean} `true` if valid file extension else `false`
 */
function checkFileExtensions(filename, allowedExt = []) {
  const fileInfo = String(filename)?.toLowerCase()?.split('.');
  return allowedExt.includes(fileInfo[fileInfo.length - 1]);
}

/**
 * Get icon for specific file type
 * @param {string } filename filenme with file extension
 * @returns { string } icon name from growmodo-iconfont
 */
function getFileTypeIcon(filename) {
  return `icon-${
    checkFileExtensions(filename, ['woff', 'woff2', 'ttf', 'otf'])
      ? 'type-square'
      : checkFileExtensions(filename, [
          'webp',
          'ico',
          'jpg',
          'jpeg',
          'png',
          'svg',
          'gif',
          'ai',
          'eps',
          'psd',
        ])
      ? 'image-03'
      : 'file-attachment-04'
  }`;
}

/**
 * Get file extension for supported images to display
 * @returns { array } list of supported image file extension
 */
function getSupportedDisplayImageExt() {
  return ['webp', 'ico', 'jpg', 'jpeg', 'png', 'svg', 'gif'];
}

/**
 *
 * @param {String} url url of the shared loom video
 * @returns {String} url of the embeded loom video
 */
function urlShareToEmbed(url) {
  url = url.replace('loom.com/share/', 'loom.com/embed/');
  url = url.replace('youtube.com/watch?v=', 'youtube.com/embed/');
  url = url.replace('youtu.be/', 'youtube.com/embed/');
  url = url.replace('www.vimeo.com/', 'player.vimeo.com/video/');
  url = url.replace('vimeo.com/', 'player.vimeo.com/video/');
  return url;
}

/**
 *
 * @param {String} html HTML string
 * @returns {String} clean HTML string
 */
function removeEmptyTags(html) {
  const regex = /<(?!\/)([^>]+)><\/([^>]+)>/gi;
  // Ensure only identical pairs are matched.
  const matches = [...html.matchAll(regex)].filter(
    (match) => match[1] === match[2]
  );
  if (matches.length > 0) {
    return removeEmptyTags(
      matches.reduce((cleaned, match) => cleaned.replace(match[0], ''), html)
    );
  }
  return html;
}

/**
 *
 * @param {String} html HTML string
 * @returns {String} clean HTML string
 */
function cleanMarkup(html) {
  const replaced = html
    .replace(/ style="[^"]+"/gi, '') // Remove all style attributes.
    .replace(/ class="[^"]+"/gi, '') // Remove all class attributes.
    .replace(/<\/p><p>/gi, '</p><br><p>') // Add br on p.
    .replace(/<\/div><div>/gi, '</div><br><div>') // Add br on div.
    .replace(/<\/p><div>/gi, '</p><br><div>') // Add br on p - div.
    .replace(/<\/div><p>/gi, '</div><br><p>') // Add br on div - p.
    .replace(/<\/?span([^>]*)>/gi, '') // Remove span tags.
    .replace(/<\/?div([^>]*)>/gi, '') // Remove div tags.
    .replace(/<\/?p ([^>]*)>/gi, '') // Remove p with attributes.
    .replace(/<\/?p>/gi, '') // Remove p tags.
    .replace(/<p><([ou]l)>/gi, '<$1>') // Remove all paragraphs surrounding a list
    .replace(/<\/([ou]l)><\/p>/gi, '</$1>');
  return removeEmptyTags(replaced); // Remove all empty tag pairs.
}

export {
  ColorThief,
  greetings,
  checker,
  base64,
  cleanPadLimiter,
  objectCopy,
  checkIfObject,
  checkIfArray,
  searchInObjectArray,
  objectCompare,
  formatMoney,
  relativeDate,
  filterUniqueKey,
  url2link,
  tryClearInterval,
  changeInputFocusOnKey,
  defaultModKey,
  getImageFromBase64str,
  getImageStringFromURL,
  avatarNameAbbr,
  numberToWords,
  extractErrorMessageString,
  checkFileExtensions,
  getFileTypeIcon,
  getSupportedDisplayImageExt,
  urlShareToEmbed,
  removeEmptyTags,
  cleanMarkup,
};
