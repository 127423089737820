<template>
  <q-btn
    no-caps
    unelevated
    :ripple="{ color: 'accent' }"
    color="primary"
    class="font-medium"
    :class="{
      ['rounded']: !$q.screen.xs || alwaysShowLabel,
      ['not-gm']: $q.screen.xs,
      [`button-${format}`]: true,
    }"
    :round="$q.screen.xs && !alwaysShowLabel"
  >
    <div class="row items-center" :class="{ ['reverse']: iconRight }">
      <q-icon
        v-if="icon && (alwaysShowIcon || $q.screen.xs)"
        :name="icon"
        :size="iconSize"
        :class="{ [iconClass]: true }"
      />
      <span
        v-if="$q.screen.gt.xs || alwaysShowLabel"
        class="font-medium"
        :class="{
          ['q-pl-xs']: icon && (alwaysShowIcon || $q.screen.xs),
          [labelClass]: true,
        }"
      >
        {{ label }}
      </span>
    </div>
    <tooltip-pro v-if="$q.screen.xs" :delay="150" :hide-delay="150">
      {{ label }}
    </tooltip-pro>

    <slot />
  </q-btn>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'DynamicButton',

  props: {
    label: {
      type: String,
      default: '',
    },
    labelClass: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: '',
    },
    iconClass: {
      type: String,
      default: '',
    },
    iconRight: {
      type: Boolean,
      default: false,
    },
    iconSize: {
      type: String,
      default: 'sm',
    },
    alwaysShowIcon: {
      type: Boolean,
      default: false,
    },
    alwaysShowLabel: {
      type: Boolean,
      default: false,
    },
    format: {
      type: String,
      default: 'md',
    },
  },
});
</script>
