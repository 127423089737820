import { reactive } from 'vue';
import { countries } from 'per-country';

export default function () {
  return {
    isOnline: navigator.onLine,

    messagesDialogs: reactive({
      requestUpgrade: {
        cancel: false,
        messageTitle:
          'Thank you. We have received your request to change your plan.',
        messageDescription:
          'We will notify you as soon as your plan changed (usually within 24 hours).',
      },
      editingUnsaved: {
        okText: 'Yes',
        cancelText: 'Back to Edit',
        messageTitle:
          "Your changes haven't been saved, do you want to discard them?",
      },
      confirmLogout: {
        okText: 'Log Out',
        messageTitle: 'Are you sure you want to logout of your account?',
      },
      confirmDelete: {
        icon: 'icon-trash-01',
        // iconColor: 'negative',
        // iconBgColor: 'red-1',
        okText: 'Delete',
        messageTitle: 'Confirm to delete file?',
        // suggestAlternative: true,
      },
    }),

    greetings: reactive({
      morning: 'Good Morning',
      afternoon: 'Good Afternoon',
      evening: 'Good Evening',
    }),

    employeeCount: reactive({}),

    socialMedia: reactive({}),

    allCountries: reactive(countries),
  };
}
