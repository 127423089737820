import config from 'src/assets/_config';
import { LocalStorage } from 'quasar';
import { base64 } from 'src/assets/scripts/functions';

export default {
  /**
   * Add LocalStorage Authorization
   */
  addAuth: (token) => {
    LocalStorage.set(config?.localStorageKeys?.auth, token);
  },
  /**
   * Get LocalStorage Authorization
   */
  getAuth: () => {
    return LocalStorage.getItem(config?.localStorageKeys?.auth);
  },
  /**
   * Remove LocalStorage Authorization
   */
  removeAuth: () => {
    LocalStorage.remove(config?.localStorageKeys?.auth);
  },

  addAPICache: (url_path_key, json_data) => {
    try {
      const key = base64.encode(url_path_key);
      const data = JSON.stringify(json_data);
      LocalStorage.set(key, data);
      if (process.env.DEV_MODE)
        console.info('Data has been Cached:', url_path_key, json_data);
      return true;
    } catch (e) {
      if (process.env.DEV_MODE) console.error(e);
      return false;
    }
  },

  getAPICache: (url_path_key) => {
    try {
      const key = base64.encode(url_path_key);
      const response = JSON.parse(LocalStorage.getItem(key));
      if (process.env.DEV_MODE)
        console.info('Data from Cache:', url_path_key, response);
      return response;
    } catch (e) {
      if (process.env.DEV_MODE) console.error(e);
      return null;
    }
  },

  getAPIHost: () => {
    return LocalStorage.getItem(config?.localStorageKeys.api_host);
  },

  setAPIHost: (api_host) => {
    return LocalStorage.set(config?.localStorageKeys.api_host, api_host);
  },

  removeAPIHost: () => {
    return LocalStorage.remove(config?.localStorageKeys.api_host);
  },
};
