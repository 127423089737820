import { LocalStorage } from 'quasar';
const pkjson = process.env.PKJSON;

/**
 * Application Meta Data
 */
const metaData = {
  // Document title
  title: `${pkjson.productName}`,
  // optional; sets final title as "Page | My Website", useful for multiple level meta
  titleTemplate: (title) => `${title} | Growmodo`,
  // Meta tags
  meta: {
    description: { name: 'description', content: pkjson.description },
    keywords: { name: 'keywords', content: pkjson.productName },
    equiv: {
      'http-equiv': 'Content-Type',
      content: 'text/html; charset=UTF-8',
    },
    // note: for Open Graph type metadata you will need to use SSR, to ensure page is rendered by the server
    ogTitle: {
      property: 'og:title',
      // optional; similar to titleTemplate, but allows templating with other meta properties
      template(ogTitle) {
        return `${ogTitle}`;
      },
    },
  },
  // CSS tags
  link: {},
  // JS tags
  script: {},
  // <html> attributes
  htmlAttr: {},
  // <body> attributes
  bodyAttr: {},
  // <noscript> tags
  noscript: {
    default: 'Javascript is Required',
  },
};

const icons = {
  growmodo: {
    'logo:icon-white': '/logo/GrowmodoIcon-White.svg',
    'logo:icon-black': '/logo/GrowmodoIcon-Black.svg',
    'logo:icon-colored-light': '/logo/GrowmodoIcon-Colored-Light.svg',
    'logo:icon-colored-dark': '/logo/GrowmodoIcon-Colored-Dark.svg',
    'logo:text-white': '/logo/GrowmodoTextIcon-White.svg',
    'logo:text-black': '/logo/GrowmodoTextIcon-Black.svg',
  },
  stepper: {
    'step:inactive': '/icons/components/step-inactive.svg',
    'step:active': '/icons/components/step-active.svg',
    'step:done': '/icons/components/step-done.svg',
  },
};

export default {
  /** Package Information */
  pkjson,

  /** Default Meta Data */
  metaData,

  /**
   * Custom mapping icons
   */
  icons,

  /**
   * My Defaults
   */
  default: {
    message: {
      welcome: 'Hello, friend.',
      bye: 'Goodbye, friend!',
      bug: `
A bug is never just a mistake.
It represents something bigger.
An error of thinking.
That makes you who you are.

`,
      prod: `

 .d8888b.  888                       888
d88P  Y88b 888                       888
Y88b.      888                       888
 "Y888b.   888888  .d88b.  88888b.   888
    "Y88b. 888    d88""88b 888 "88b  888
      "888 888    888  888 888  888  Y8P
Y88b  d88P Y88b.  Y88..88P 888 d88P
 "Y8888P"   "Y888  "Y88P"  88888P"   888
                           888
                           888
                           888


Using this console may allow attackers to impersonate you and steal your information using an attack called Self-XSS.
Do not enter or paste code that you do not understand.

`,
    },
    color: {
      addressbar: '#121212',
    },
    class: {
      activeInput: 'form_active_input',
    },
    html2pdf: {
      margin: [0.5, 0.75],
      enableLinks: true,
      image: { type: 'jpeg', quality: 0.95 },
      html2canvas: {
        dpi: 192,
        logging: false,
        letterRendering: true,
      },
      jsPDF: {
        orientation: 'portrait',
        format: 'a4',
        putOnlyUsedFonts: true,
        compress: true,
        unit: 'in',
      },
    },
  },

  /**
   * Local Storage Keys
   */
  localStorageKeys: {
    auth: 'authToken',
    api_host: 'api_host',
  },

  /**
   * API defaults
   */
  api: {
    baseURL: LocalStorage.getItem('api_host') || process.env.API,
  },

  /**
   * API defaults
   */
  cdn: {
    baseURL: process.env.CDN,
    platforms: '/public/icons/platforms',
    country_flags: '/public/icons/country_flags',
    social_media: '/public/icons/social_media',
    testimonials: '/public/images/testimonials',
    gif: '/public/gif',
    tmp: '/public/tmp',
  },

  publicPathPattern: [
    '^/signup$',
    '^/signup/(.*)',
    '^/invitation/(.*)',
    '^/checkout/(.*)',
  ],
  noLoginPath: ['/signup', '/invitation'],

  regexp: {
    https: /^https?:\/\//i,
    url: /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[\-;:&=\+\$,\w]+@)?[A-Za-z0-9\.\-]+|(?:www\.|[\-;:&=\+\$,\w]+@)[A-Za-z0-9\.\-]+)((?:\/[\+~%\/\.\w\-_]*)?\??(?:[\-\+=&;%@\.\w_]*)#?(?:[\.\!\/\\\w]*))?)/,
    email:
      /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/,
    username: /^[a-zA-Z]([._-]?[a-zA-Z0-9]+){3}$/,
    password: /^(.{0,7}|[^0-9]*|[^A-Z]*|[^a-z]*|[a-zA-Z0-9]*)$/,
  },
  supportedDisplayImage: ['webp', 'ico', 'jpg', 'jpeg', 'png', 'svg', 'gif'],
};
