<template>
  <q-tooltip
    @show="onShow"
    class="tooltip tooltip-pro q-pa-none"
    :class="`tooltip-arrow-${arrowClass} shadow-${shadow}`"
    ref="tooltipRef"
    transition-duration="0"
    transition-show="fade"
    transition-hide="fade"
  >
    <div
      class="tooltip-text relative-position"
      :class="`bg-${bgColor} text-${color}`"
    >
      <slot />
    </div>
    <div
      v-if="arrowClass !== 'none'"
      class="tooltip-arrow absolute"
      :class="`bg-${bgColor} shadow-${shadow}`"
    />
  </q-tooltip>
</template>

<script>
import { defineComponent, computed, ref } from 'vue';

export default defineComponent({
  name: 'TooltipPro',

  props: {
    arrow: {
      type: String,
      default: 'none',
    },
    duration: {
      type: Number,
      default: null,
    },
    bgColor: {
      type: String,
      default: 'primary',
    },
    color: {
      type: String,
      default: 'white',
    },
    shadow: {
      type: [Number, String],
      default: 0,
    },
  },

  setup(props) {
    const tooltipRef = ref(null);
    const arrowClass = computed(() => {
      let arrowClassCorrected = props.arrow.replace(' ', '-');
      let allowedClasses = [
        'none',
        'top',
        'top-left',
        'top-right',
        'bottom',
        'bottom-left',
        'bottom-right',
        'left',
        'left-top',
        'left-bottom',
        'right',
        'right-top',
        'right-bottom',
      ];
      if (!allowedClasses.includes(arrowClassCorrected)) {
        console.error(
          `Value "${arrowClassCorrected}" not allowed for "arrow" prop. Use one of these: `,
          allowedClasses
        );
        return 'top';
      }

      return props.arrow.replace(' ', '-');
    });

    const onShow = () => {
      if (props.duration) {
        setTimeout(() => {
          if (tooltipRef.value && tooltipRef.value) tooltipRef.value.hide();
        }, props.duration);
      }
    };

    return {
      arrowClass,
      tooltipRef,
      onShow,
    };
  },
});
</script>

<style lang="scss">
.tooltip-pro {
  background-color: transparent !important;
}
.tooltip {
  overflow: visible;
  border-radius: $border;
  &-text {
    z-index: 10;
    padding: 5px 10px;
    border-radius: $border;
    font-weight: $font-medium;
  }
  &-arrow {
    z-index: 5;
    width: 20px;
    height: 20px;
    transform: translateX(-50%) rotate(-45deg) skew(-20deg, -20deg);

    // top arrows
    [class*='tooltip-arrow-top'] & {
      top: 0px;
    }
    .tooltip-arrow-top & {
      left: 50%;
    }
    .tooltip-arrow-top-left & {
      left: 20px;
    }
    .tooltip-arrow-top-right & {
      right: 0px;
    }

    // bottom arrows
    [class*='tooltip-arrow-bottom'] & {
      bottom: 0px;
    }
    .tooltip-arrow-bottom & {
      left: 50%;
    }
    .tooltip-arrow-bottom-left & {
      left: 20px;
    }
    .tooltip-arrow-bottom-right & {
      right: 0px;
    }

    // left arrows
    [class*='tooltip-arrow-left'] & {
      transform: translateY(-50%) rotate(45deg) skew(-20deg, -20deg);
      left: 0px;
    }
    .tooltip-arrow-left & {
      top: 50%;
    }
    .tooltip-arrow-left-top & {
      top: 20px;
    }
    .tooltip-arrow-left-bottom & {
      bottom: -10px;
    }

    // right arrows
    [class*='tooltip-arrow-right'] & {
      transform: translateY(-50%) rotate(45deg) skew(-20deg, -20deg);
      right: 0px;
    }
    .tooltip-arrow-right & {
      top: 50%;
    }
    .tooltip-arrow-right-top & {
      top: 20px;
    }
    .tooltip-arrow-right-bottom & {
      bottom: -10px;
    }
  }

  // close button
  &-close {
    pointer-events: all !important;
  }
}
</style>
