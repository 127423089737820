import { mapState, mapActions } from 'pinia';
import useUser from 'stores/user';
import useHelpers from 'stores/helpers';

export default {
  computed: {
    ...mapState(useUser, ['geolocation']),
  },

  methods: {
    ...mapActions(useHelpers, ['getIp']),
    ...mapActions(useUser, ['updateGeoInformation']),

    async getIpInformation() {
      this.getIp('json').then((info) => {
        this.updateGeoInformation(info, { merge: true });
      });
    },

    async askGeoPermission() {
      // Get Geo Information
      navigator.geolocation.getCurrentPosition(
        this.onGeoAllowed,
        this.onGeoError
      );
    },

    onGeoAllowed(info) {
      console.info('Geo Location Allowed', info);
      this.updateGeoInformation(info, { merge: true });
    },

    onGeoError(err) {
      console.error(`Geo Location ERROR(${err.code}): ${err.message}`);
    },
  },
};
