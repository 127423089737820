/**
 * Update components default icons
 */
module.exports = {
  name: 'GrowmodoIcons',
  type: {
    positive: 'icon-check-circle',
    negative: 'icon-alert-triangle',
    info: 'icon-info-circle',
    warning: 'icon-alert-octagon',
  },
  arrow: {
    arrowUp: 'icon-chevron-up',
    right: 'icon-chevron-right',
    down: 'icon-chevron-down',
    left: 'icon-chevron-left',
    dropdown: 'icon-chevron-down',
  },
  chevron: {
    left: 'icon-chevron-left',
    right: 'icon-chevron-right',
  },
  colorPicker: {
    spectrum: 'icon-gradient',
    tune: 'icon-tune',
    palette: 'icon-style',
  },
  pullToRefresh: {
    icon: 'icon-refresh-cw-01',
  },
  carousel: {
    left: 'icon-chevron-left',
    right: 'icon-chevron-right',
    up: 'icon-chevron-up',
    down: 'icon-chevron-down',
    navigationIcon: 'icon-circle',
  },
  chip: {
    remove: 'icon-x-close',
    selected: 'icon-check-circle',
  },
  datetime: {
    arrowLeft: 'icon-chevron-left',
    arrowRight: 'icon-chevron-right',
    now: 'iocon-clock',
    today: 'icon-calendar',
  },
  editor: {
    bold: 'icon-bold-01',
    italic: 'icon-italic-01',
    strikethrough: 'icon-strikethrough-01',
    underline: 'icon-underline-01',
    unorderedList: 'icon-list',
    orderedList: 'icon-list-numbered',
    subscript: 'icon-align-bottom-01',
    superscript: 'icon-align-top-arrow-01',
    hyperlink: 'icon-link-01',
    toggleFullscreen: 'icon-maximize-02',
    quote: 'icon-quote',
    left: 'icon-align-left',
    center: 'icon-align-center',
    right: 'icon-align-right',
    justify: 'icon-align-justify',
    print: 'icon-printer',
    outdent: 'icon-right-indent-01',
    indent: 'icon-left-indent-01',
    removeFormat: 'icon-format-clear',
    formatting: 'icon-type-02',
    fontSize: 'icon-type-01',
    align: 'icon-align-left',
    hr: 'icon-minus',
    undo: 'icon-reverse-left',
    redo: 'icon-reverse-right',
    heading: 'icon-heading-01',
    code: 'icon-code-02',
    size: 'icon-heading-01',
    font: 'icon-type-square',
    viewSource: 'icon-code-02',
  },
  expansionItem: {
    icon: 'icon-chevron-down',
    denseIcon: 'icon-chevron-up',
  },
  fab: {
    icon: 'icon-plus',
    activeIcon: 'icon-x-close',
  },
  field: {
    clear: 'icon-x-close',
    error: 'icon-alert-circle',
  },
  pagination: {
    first: 'icon-chevron-left-double',
    prev: 'icon-chevron-left',
    next: 'icon-chevron-right',
    last: 'icon-chevron-right-double',
  },
  rating: {
    icon: 'icon-star-01',
  },
  stepper: {
    done: 'icon-check-circle',
    active: 'icon-pencil-02',
    error: 'icon-alert-triangle',
  },
  tabs: {
    left: 'icon-chevron-left',
    right: 'icon-chevron-right',
    up: 'icon-chevron-up',
    down: 'icon-chevron-down',
  },
  table: {
    arrowUp: 'icon-arrow-narrow-up',
    warning: 'icon-alert-triangle',
    firstPage: 'icon-chevron-left-double',
    prevPage: 'icon-chevron-left',
    nextPage: 'icon-chevron-right',
    lastPage: 'icon-chevron-right-double',
  },
  tree: {
    icon: 'icon-play',
  },
  uploader: {
    done: 'icon-check-circle',
    clear: 'icon-x-close',
    add: 'icon-plus-square',
    upload: 'icon-upload-cloud-01',
    removeQueue: 'icon-clear-all',
    removeUploaded: 'icon-done-all',
  },
};
