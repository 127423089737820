import { boot } from 'quasar/wrappers';
import { AddressbarColor, getCssVar } from 'quasar';
import { greetings } from 'src/assets/scripts/functions';
import config from 'src/assets/_config';

// Custom Global Component(s)
import TooltipPro from 'src/components/Globals/TooltipPro.vue';
import CloseButton from 'src/components/Globals/CloseButton.vue';
import DynamicDialogConfirmation from 'src/components/Globals/DynamicDialogConfirmation.vue';
import ExternalLink from 'src/components/Globals/ExternalLink.vue';
import CardItem from 'src/components/Globals/CardItem.vue';
import GMToggle from 'src/components/Globals/GMToggle.vue';
import ImageLightbox from 'src/components/Globals/ImageLightbox.vue';
import DynamicButton from 'src/components/Helpers/DynamicButton.vue';

export default boot(async ({ app }) => {
  // Greetings on Console
  process.env.DEV_MODE ? greetings.console.dev() : greetings.console.prod();

  // App Config
  AddressbarColor.set(config.color?.addressbar);

  // Global Properties
  app.config.globalProperties.$config = config;
  app.config.globalProperties.$lskeys = config.localStorageKeys;
  app.config.globalProperties.$getCssVar = getCssVar;
  app.config.globalProperties.$DEV_MODE = process.env.DEV_MODE;
  app.config.globalProperties.$DEV = process.env.DEV;
  app.config.globalProperties.$DEBUG = process.env.DEBUG;

  // Global Components
  app.component('close-button', CloseButton);
  app.component('tooltip-pro', TooltipPro);
  app.component('dynamic-dialog-confirmation', DynamicDialogConfirmation);
  app.component('external-link', ExternalLink);
  app.component('card-item', CardItem);
  app.component('gm-toggle', GMToggle);
  app.component('image-lightbox', ImageLightbox);
  app.component('dynamic-button', DynamicButton);
});

export {};
