export default {
  methods: {
    /**
     * @param {Object} question - the question object
     * @returns {Object} users answer
     */
    formatDynamicAnswer(question) {
      let answer = { value: '', alternative_answer: '' };
      // Answer
      switch (question.type) {
        case 'url':
        case 'phone':
        case 'textarea1':
        case 'textarea2':
        case 'textfield':
        case 'select_brand':
        case 'video_walkthrough':
        case 'upload_single':
        case 'select_tasktype':
        case 'select_projecttype':
        case 'select_taskcategory':
        case 'select_projectcategory':
          answer.value = '';
          break;

        case 'select_multiple':
        case 'checkbox':
        case 'upload_multiple':
          answer.value = [];
          break;

        case 'radio':
        case 'select_platform':
          answer.value = {};
          break;

        case 'website_content':
          answer.value = {
            info: '',
            uploads: [],
          };
          break;

        default:
          break;
      }
      // Alternative Answer
      switch (question.alternative_type) {
        case 'url':
        case 'phone':
        case 'textarea1':
        case 'textarea2':
        case 'textfield':
        case 'select_brand':
        case 'video_walkthrough':
        case 'upload_single':
        case 'select_tasktype':
        case 'select_projecttype':
        case 'select_taskcategory':
        case 'select_projectcategory':
          answer.alternative_answer = '';
          break;

        case 'select_multiple':
        case 'checkbox':
        case 'upload_multiple':
          answer.alternative_answer = [];
          break;

        case 'radio':
        case 'select_platform':
          answer.alternative_answer = {};
          break;

        case 'website_content':
          answer.alternative_answer = {
            info: '',
            uploads: [],
          };
          break;

        default:
          break;
      }

      return answer;
    },
  },
};
