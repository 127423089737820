import { colors, copyToClipboard } from 'quasar';

export default {
  methods: {
    copyToClipboard(
      stringVal,
      message,
      icon = 'icon-check-circle-broken',
      notifyOptions = {}
    ) {
      if (!stringVal || !message) return;
      copyToClipboard(stringVal).then(() => {
        this.$q.notify({
          icon,
          message,
          ...notifyOptions,
        });
      });
    },

    isColorDark(colorVal) {
      return colorVal ? colors.brightness?.(colorVal) < 128 : false;
    },

    convertToKey(dirtyString) {
      return (dirtyString || '')
        .toLowerCase()
        .replace(/[^a-z0-9 ]/g, '')
        .replace(/\s/g, '_');
    },
  },
};
