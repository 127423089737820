<template>
  <a
    v-if="urlLabel"
    :href="urlLink"
    target="_blank"
    class="q-col-gutter-sm row inline items-center text-primary-700 hover-text-secondary"
    :class="`${externalIcon ? 'hover-visibility' : ''} ${color}`"
  >
    <q-icon
      v-if="icon"
      :name="icon"
      size="xs"
      class="hover-text-secondary"
      :class="{ [iconClass]: iconClass }"
      color="primary-700"
    />
    <span
      :class="{ ['underline']: underline, [labelClass]: labelClass }"
      class="text-secondary hover-text-secondary"
    >
      {{ urlLabel }}
    </span>
    <q-icon
      v-if="externalIcon"
      size="12px"
      :name="externalIcon"
      class="hover-visibility-show"
    />
  </a>
</template>

<script>
import { defineComponent } from 'vue';
import { url2link } from 'src/assets/scripts/functions';

export default defineComponent({
  name: 'ExternalLink',

  props: {
    url: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    format: {
      type: String,
      default: '',
    },
    externalIcon: {
      type: [Boolean, String],
      default: 'icon-link-external-01',
    },
    color: {
      type: String,
      default: 'text-primary',
    },
    underline: {
      type: Boolean,
      default: false,
    },
    labelClass: {
      type: String,
      default: '',
    },
    iconClass: {
      type: String,
      default: '',
    },
  },

  computed: {
    urlLabel() {
      if (this.label) return this.label;
      const xurl = url2link(this.url);
      let url = '';
      try {
        url = xurl ? new URL(xurl) : {};
      } catch (e) {
        if (this.$DEV_MODE) console.error('Received invalid url');
      }
      return (
        (this.format
          ? url?.[this.format]
          : url?.hostname + (url?.pathname !== '/' ? url?.pathname : '')) ||
        this.url
      );
    },

    urlLink() {
      return url2link(this.url);
    },
  },
});
</script>
