import { defineStore } from 'pinia';

import state from './state';
import * as getters from './getters';
import * as actions from './actions';

export default defineStore('useUser', {
  state,
  getters,
  actions: {
    ...actions,
  },
});
