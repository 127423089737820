import { api, cdn } from 'app/src/boot/apis';
import fnStore from '../fn.store';
import useUser from 'stores/user';

// Application Methods
async function getIp(format, ip, key) {
  let url = `https://ipapi.co/${ip || format || 'json'}/${key || ''}`;
  return await api
    .get(url)
    .then((res) => {
      const data = res.data;
      fnStore.addAPICache(url, data);
      return data;
    })
    .catch((e) => {
      const cache_data = fnStore.getAPICache(url);
      return cache_data || e;
    });
}

function isReachable(url = process.env.API) {
  return fetch(url, { method: 'HEAD', mode: 'no-cors' })
    .then((resp) => {
      if (process.env.DEBUGGING) console.info('[conn test result]:', resp);
      return resp && (resp.ok || resp.type === 'opaque');
    })
    .catch((err) => {
      if (process.env.DEBUGGING) console.warn('[conn test failure]:', err);
      return false;
    });
}

async function checkIfOnline() {
  if (!navigator.onLine) {
    this.isOnline = false;
    return false;
  } else {
    return await this.isReachable()
      .then((e) => {
        this.isOnline = e;
        return e;
      })
      .catch((err) => {
        if (process.env.DEBUGGING) console.error(err);
        this.isOnline = false;
        return false;
      });
  }
}
// End of Application Method

async function getSocialMediaPlatforms() {
  const url = '/miscs/social-platforms';
  return await api
    .get(url)
    .then((res) => {
      const data = res.data;
      if (data?.data) this.socialMedia = data?.data;
      fnStore.addAPICache(url, data);
      return data;
    })
    .catch((e) => {
      const cache_data = fnStore.getAPICache(url);
      if (cache_data?.data) this.socialMedia = cache_data?.data;
      return cache_data || e;
    });
}

function formatMediaPlatformIconSrc(mediaPlatform) {
  return `icon-social-${
    this.getSocialInfo(mediaPlatform)?.icon || mediaPlatform
  }`;
}

function getSocialInfo(mediaPlatform) {
  if (!mediaPlatform) return;
  return this.socialMedia[mediaPlatform];
}

function getCountryFlag(country) {
  return `${cdn.baseURL}${cdn.country_flags}/${country}`;
}

function getCountryInfo(country) {
  return this.allCountries.filter(
    (e) => e.code === country || e.name === country
  )[0];
}

function getCurrency(currency_code = 'USD') {
  let country = this.allCountries.filter(
    (e) => e.currency.code === currency_code
  );
  if (!country || !country?.length) return '';
  return country[0]?.currency;
}

async function getEmployeeCount() {
  const url = '/miscs/employee-counts';
  return await api
    .get(url)
    .then((res) => {
      const data = res.data;
      if (data?.data) {
        for (const emp_cnt of data.data) {
          this.employeeCount[emp_cnt.id] = emp_cnt;
        }
      }
      fnStore.addAPICache(url, data);
      return data;
    })
    .catch((e) => {
      const cache_data = fnStore.getAPICache(url);
      if (cache_data?.data) {
        for (const emp_cnt of cache_data.data) {
          this.employeeCount[emp_cnt.id] = emp_cnt;
        }
      }
      return cache_data || e;
    });
}

async function deleteFile(fileId) {
  if (!fileId) return;
  const url = `/file/${fileId}`;
  return await api
    .delete(url)
    .then((res) => {
      const data = res.data;
      return data;
    })
    .catch((e) => {
      return e;
    });
}

async function deleteFolder(folderId) {
  if (!folderId) return;
  const url = `/folder/${folderId}`;
  return await api
    .delete(url)
    .then((res) => {
      const data = res.data;
      return data;
    })
    .catch((e) => {
      return e;
    });
}

async function getSingleFile(fileId, cache_first = false) {
  const url = `/file/${fileId}`;
  let cache_data;
  if (cache_first) {
    cache_data = fnStore.getAPICache(url);
    if (cache_data?.success) return cache_data;
  }
  return await api
    .get(url)
    .then((res) => {
      const data = res.data;
      fnStore.addAPICache(url, data);
      return data;
    })
    .catch((e) => {
      return cache_data || e;
    });
}

async function getFiles(payload = { sort: 'desc' }, orgId) {
  if (!orgId) {
    const user = useUser();
    orgId = user.activeOrgID;
    if (!orgId) return;
  }
  const url = `/files/organizations/${orgId}`;
  return await api
    .get(url, { params: payload })
    .then((res) => {
      const data = res.data;
      fnStore.addAPICache(url, data);
      return data;
    })
    .catch((e) => {
      const cache_data = fnStore.getAPICache(url);
      return cache_data || e;
    });
}

async function createFolder(payload, orgId) {
  if (!orgId) {
    const user = useUser();
    orgId = user.activeOrgID;
    if (!orgId) return;
  }
  const url = `/folder/organizations/${orgId}`;
  return await api
    .post(url, payload)
    .then((res) => {
      const data = res.data;
      return data;
    })
    .catch((e) => {
      return e;
    });
}

async function uploadFile(payload, orgId) {
  if (!orgId) {
    const user = useUser();
    orgId = user.activeOrgID;
    if (!orgId) return;
  }
  const url = `/file/organizations/${orgId}`;
  return await api
    .post(url, payload)
    .then((res) => {
      const data = res.data;
      return data;
    })
    .catch((e) => {
      return e;
    });
}

async function renameFile(fileId, newFileName) {
  const url = `/file/${fileId}/rename`;
  return await api
    .put(url, {
      name: newFileName,
    })
    .then((res) => {
      const data = res.data;
      return data;
    })
    .catch((e) => {
      return e;
    });
}

async function renameFolder(folderId, newFolderName) {
  const url = `/folder/${folderId}/rename`;
  return await api
    .put(url, {
      name: newFolderName,
    })
    .then((res) => {
      const data = res.data;
      return data;
    })
    .catch((e) => {
      return e;
    });
}

async function downloadFolder(folderId) {
  const url = `/folder/${folderId}/download`;
  return await api
    .get(url)
    .then((res) => {
      const data = res.data;
      return data;
    })
    .catch((e) => {
      return e;
    });
}

async function getGoogleFonts(sort, preferCache) {
  const url = `mirror/googlefonts${sort ? '?sort=' + sort : ''}`;
  const cache_data = fnStore.getAPICache(url);
  if (preferCache && cache_data?.success) return cache_data;
  return await api
    .get(url)
    .then((res) => {
      const data = res.data;
      fnStore.addAPICache(url, data);
      return data;
    })
    .catch((e) => {
      return cache_data || e;
    });
}

export {
  getIp,
  isReachable,
  checkIfOnline,
  getSocialMediaPlatforms,
  getSocialInfo,
  formatMediaPlatformIconSrc,
  getCountryFlag,
  getCountryInfo,
  getCurrency,
  getEmployeeCount,
  // Files
  getFiles,
  getSingleFile,
  deleteFile,
  deleteFolder,
  createFolder,
  uploadFile,
  renameFile,
  renameFolder,
  downloadFolder,
  // Google
  getGoogleFonts,
};
