import { LoadingBar } from 'quasar';
import { getImageStringFromURL } from 'src/assets/scripts/functions';
import html2pdf from 'html2pdf.js';

export default {
  data() {
    return {
      convertingPDF: false,
    };
  },

  watch: {
    convertingPDF: {
      async handler(val) {
        if (val) LoadingBar.start();
        else LoadingBar.stop();
      },
    },
  },

  methods: {
    async exportToPDF(filename, ids, withFooter) {
      this.convertingPDF = true;
      const notif = this.$q.notify({
        group: false, // required to be updatable
        spinner: true,
        progress: false,
        message: 'Generating PDF Document',
        timeout: 0,
      });
      let percentage = 10;
      const interval = setInterval(() => {
        percentage = Math.min(99, percentage + Math.floor(Math.random() * 10));
        notif({
          caption: `Processing... ${percentage}%`,
        });
        if (percentage === 99) {
          notif({
            message: 'Finalizing PDF',
            caption: undefined,
          });
          clearInterval(interval);
        }
      }, 500);
      const element = await this.generateElement4PDF(ids, withFooter);
      this.convertNow(element, filename)
        .then((status) => {
          // Notify PDF Status
          notif({
            message: status ? 'PDF Generated' : 'Failed Generating PDF',
            icon: status ? 'icon-check-circle-broken' : 'icon-alert-triangle',
            iconColor: status ? 'secondary' : 'negative',
            spinner: false,
            caption: undefined,
            timeout: 1500,
          });
        })
        .finally(() => {
          // Disable processing status
          this.convertingPDF = false;
          // Clear Interval
          clearInterval(interval);
        });
    },

    async generateElement4PDF(ids, withFooter = false) {
      const element = document.createElement('div');
      for (const id of ids) {
        let el = document.getElementById(id);
        if (!el) continue;
        el = el.cloneNode(true);
        // Remove Loader
        const imgsLoader = el.querySelectorAll(
          '.q-img__loading, .q-img__image--waiting'
        );
        for (const e of imgsLoader) {
          e.remove?.();
        }
        // Replace Image Source with Base64
        const imgs = el.querySelectorAll('img');
        for (const img of imgs) {
          let newSrc = await getImageStringFromURL(img.src, false);
          img.src = newSrc;
        }
        // Replace Video with URL
        const vid_wrapper = el.querySelectorAll('.q-video');
        for (const vid of vid_wrapper) {
          let video_link = document.createElement('a');
          const vid_src = vid.querySelector('iframe')?.src;
          video_link.append(vid_src);
          video_link.href = vid_src;
          vid.remove();
          el.append(video_link);
        }
        element.append(el);
      }

      // Footer Section
      if (withFooter) {
        const footer = document.createElement('div');
        footer.classList.add(
          'full-width',
          'text-center',
          'text-caption',
          'color-body2',
          'font-thin',
          'letter-space-normal',
          'q-pt-xl'
        );
        footer.append(
          '© ' +
            date.formatDate(
              this.$root.realtimeDate || Date.now(),
              'MMMM DD, YYYY - HH:mm:ss'
            )
        );
        element.append(footer);
      }
      return element;
    },

    async convertNow(element, filename) {
      filename = `${filename || 'Output'}.pdf`.replaceAll(' ', '_');
      return await html2pdf()
        .set({ filename, ...this.$config.default?.html2pdf })
        .from(element)
        .to('pdf')
        .save()
        .then(() => {
          return true;
        })
        .catch(() => {
          return false;
        });
    },
  },
};
