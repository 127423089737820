/**
 * THIS FILE IS GENERATED AUTOMATICALLY.
 * DO NOT EDIT.
 *
 * You are probably looking on adding startup/initialization code.
 * Use "quasar new boot <name>" and add it there.
 * One boot file per concern. Then reference the file(s) in quasar.config.js > boot:
 * boot: ['file', ...] // do not add ".js" extension to it.
 *
 * Boot files are your "main.js"
 **/



import {LoadingBar,Notify,Dialog,AppVisibility} from 'quasar'



export default { config: {"notify":{"group":false,"iconColor":"secondary","spinnerColor":"secondary","textColor":"primary","color":"white","classes":"rounded shadows-lg border-add text-h6 font-medium text-primary letter-spacing-normal q-py-sm q-px-lg notif-ui"},"dialog":{"classes":"shadows-lg"},"loadingBar":{"skipHijack":true,"position":"top","color":"secondary","size":"3px"}},plugins: {LoadingBar,Notify,Dialog,AppVisibility} }

