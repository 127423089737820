async function sendNotification(notifTitle, options = {}) {
  options = {
    icon: '/favicon.ico',
    ...options,
  };
  return new Notification(notifTitle, options);
}

async function displayNotification(notifTitle, options = {}) {
  if (!notifTitle || !options) return;
  const notifAllowed = await requestNotifPermission();
  if (notifAllowed) {
    return await sendNotification(notifTitle, options);
  }
}

async function requestNotifPermission() {
  if (window.Notification && Notification.permission === 'granted') {
    return true;
  } else if (window.Notification && Notification.permission !== 'denied') {
    return await Notification.requestPermission((status) => {
      if (status === 'granted') {
        return true;
      } else {
        return false;
      }
    });
  } else {
    return false;
  }
}

export { displayNotification, requestNotifPermission };
