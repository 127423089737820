import { date } from 'quasar';

function selectedOrg() {
  return this.activeOrgID
    ? this.organizations[this.activeOrgID] || {}
    : this.organizations;
}

function selectedOrgSubscription() {
  return this.selectedOrg?.subscriptions || {};
}

function selectedOrgStatus() {
  return this.selectedOrgSubscription?.status;
}

function selectedOrgIsMaintenance() {
  return (
    !this.selectedOrgIsCancelled &&
    this.selectedOrgSubscription?.is_maintenance === true
  );
}

function selectedOrgIsPaused() {
  return this.selectedOrgStatus === 'paused';
}

function selectedOrgIsActive() {
  return this.selectedOrgStatus === 'active';
}

function selectedOrgIsCancelled() {
  return this.selectedOrgStatus === 'cancelled';
}

function selectedOrgAccountPending() {
  if (!this.user?.id || !this.activeOrgID) return false;
  const onSignup = this.onSignupStep(this.user.signup_step);
  return (
    !this.selectedOrgIsActive &&
    !this.selectedOrgIsCancelled &&
    !onSignup &&
    this.selectedOrgStatus === 'pending'
  );
}

function selectedOrgUsers() {
  if (!this.selectedOrg?.id) return {};
  const users = this.organizationUsers?.[this.selectedOrg?.id];
  return {
    ...users,
    [this.user.id]: {
      id: this.user.id,
      username: this.user?.username,
      firstname: this.user?.firstname,
      lastname: this.user?.lastname,
      email: this.user?.email,
      role: this.user?.roles?.[0],
    },
  };
}

function selectedOrgBrands() {
  if (!this.selectedOrg?.id) return {};
  return this.organizationBrands?.[this.selectedOrg?.id] || {};
}

function selectedOrgFiles() {
  if (!this.selectedOrg?.id) return {};
  return this.organizationFiles?.[this.selectedOrg?.id] || {};
}

function selectedOrgProjects() {
  if (!this.selectedOrg?.id) return {};
  return this.organizationProjects?.[this.selectedOrg?.id] || {};
}

function selectedOrgTasks() {
  if (!this.selectedOrg?.id) return {};
  return this.organizationTasks?.[this.selectedOrg?.id] || {};
}

// Subscription
function subscriptionDaysLength() {
  const subscription_end = this.selectedOrg?.subscriptions?.subscription_end;
  const subscription_renewed =
    this.selectedOrg?.subscriptions?.subscription_renewed;
  const daysLength =
    date.getDateDiff(subscription_end, subscription_renewed, 'days') || 0;
  return daysLength;
}

function subscriptionDaysLengthWithPause() {
  const duration = this.selectedOrgSubscription.pause_on
    ?.toLowerCase()
    ?.split(' ');
  const days = !duration
    ? 0
    : duration[1] === 'month' || duration[1] === 'months'
    ? 30 * duration[0]
    : 7 * duration[0];
  return this.subscriptionDaysLength + days;
}

function subscriptionPauseDaysLength() {
  return this.subscriptionDaysLengtWithPause - this.subscriptionDaysLength;
}

function currentUserIsNotVerified() {
  if (!this.user.id) return false;
  return this.user.email_verified_at ? false : true;
}

export {
  selectedOrg,
  selectedOrgSubscription,
  selectedOrgStatus,
  selectedOrgIsMaintenance,
  selectedOrgIsActive,
  selectedOrgIsCancelled,
  selectedOrgAccountPending,
  selectedOrgUsers,
  selectedOrgBrands,
  selectedOrgFiles,
  subscriptionDaysLength,
  selectedOrgProjects,
  selectedOrgTasks,
  currentUserIsNotVerified,
  subscriptionDaysLengthWithPause,
  subscriptionPauseDaysLength,
  selectedOrgIsPaused,
};
