<template>
  <q-btn
    dense
    round
    flat
    size="md"
    :icon="icon"
    color="primary-500"
    class="hover-text-negative"
    v-close-popup
  >
    <slot />
  </q-btn>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'CloseButton',

  props: {
    icon: {
      type: String,
      default: 'icon-x-close',
    },
  },
});
</script>
