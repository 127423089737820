<template>
  <q-card flat class="q-pa-sm rounded" :style="`width: ${width}`">
    <q-card-section>
      <q-toolbar class="bg-transparent q-pa-none">
        <CardIconBox
          v-if="icon"
          :icon="icon"
          :iconColor="iconColor"
          class="no-pointer-events"
          :bgColor="iconBgColor"
        />

        <div v-if="!noclose" class="absolute-top-right">
          <close-button />
        </div>
      </q-toolbar>
    </q-card-section>
    <q-card-section class="q-pt-none">
      <div
        v-if="messageTitle"
        class="text-h5 font-demi-bold letter-space-normal word-break"
      >
        {{ messageTitle }}
      </div>
      <div
        v-if="messageDescription"
        class="text-body2 text-primary-500 letter-space-normal q-pt-md word-break"
      >
        {{ messageDescription }}
      </div>
    </q-card-section>
    <q-card-section
      class="row q-gutter-sm q-py-md"
      :class="{
        ['reverse']: suggestAlternative,
      }"
    >
      <q-btn
        v-if="cancel"
        no-caps
        :outline="!suggestAlternative"
        :color="cancelColor"
        class="col rounded slide-hover"
        :class="!suggestAlternative ? 'button-gm-outline-dark' : ''"
        @click="$emit('canceled')"
        v-close-popup
      >
        <span class="text-body1 font-medium">{{ cancelText }}</span>
      </q-btn>
      <q-btn
        v-if="ok"
        no-caps
        :outline="suggestAlternative"
        :color="okColor"
        class="col rounded slide-hover"
        :class="suggestAlternative ? 'button-gm-outline-dark' : ''"
        @click="$emit('okay')"
        v-close-popup
      >
        <span class="text-body1 font-medium">{{ okText }}</span>
      </q-btn>
    </q-card-section>
  </q-card>
</template>

<script>
import { defineComponent } from 'vue';
import CardIconBox from 'src/components/Helpers/CardIconBox.vue';

export default defineComponent({
  name: 'DynamicDialogConfirmation',

  components: { CardIconBox },

  emits: ['close', 'okay', 'canceled'],

  props: {
    noclose: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: 'icon-check-circle-broken',
    },
    iconColor: {
      type: String,
      default: 'secondary',
    },
    iconBgColor: {
      trype: String,
      default: 'accent',
    },
    width: {
      type: String,
      default: '360px',
    },
    cancel: {
      type: Boolean,
      default: true,
    },
    cancelText: {
      type: String,
      default: 'Cancel',
    },
    cancelColor: {
      type: String,
      default: 'primary',
    },
    ok: {
      type: Boolean,
      default: true,
    },
    okText: {
      type: String,
      default: 'Okay',
    },
    okColor: {
      type: String,
      default: 'primary',
    },
    messageTitle: {
      type: String,
      default: '',
    },
    messageDescription: {
      type: String,
      default: '',
    },
    suggestAlternative: {
      type: Boolean,
      default: false,
    },
  },

  unmounted() {
    this.$emit('close');
  },
});
</script>
