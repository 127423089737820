<template>
  <vue-easy-lightbox
    :imgs="imgs"
    :index="index"
    :class="{ ['dark']: dark }"
    @OnIndexChange="indexChange"
  >
    <template v-slot:close-btn="{ close }">
      <div class="absolute-top-right q-pa-sm">
        <close-button @click="close" />
      </div>
    </template>

    <template v-slot:prev-btn="{ prev }">
      <div class="absolute-left flex items-center q-px-sm">
        <q-btn
          round
          :flat="dark"
          icon="icon-chevron-left"
          color="primary-500"
          class="hover-text-secondary"
          @click="prev"
        />
      </div>
    </template>

    <template v-slot:next-btn="{ next }">
      <div class="absolute-right flex items-center q-px-sm">
        <q-btn
          round
          :flat="dark"
          icon="icon-chevron-right"
          color="primary-500"
          class="hover-text-secondary"
          @click="next"
        />
      </div>
    </template>

    <template v-slot:title>
      <div
        class="absolute-bottom flex justify-center text-body1 text-primary-100"
        style="bottom: 65px"
      >
        <span
          style="
            text-shadow: 0 0 10px #000, 0 0 20px #000, 0 0 30px #000,
              0 0 40px #000, 0 0 50px #000;
          "
        >
          {{ imgs[currentIndex]?.title }}
        </span>
      </div>
    </template>
    <template v-slot:toolbar="{ toolbarMethods }">
      <div class="absolute-bottom row items-center justify-center q-pa-sm">
        <q-btn-group push class="shadows-md">
          <q-btn
            icon="icon-zoom-in"
            color="primary-500"
            class="q-pa-sm"
            @click="toolbarMethods.zoomIn"
          >
            <tooltip-pro style="z-index: 9999">Zoom In</tooltip-pro>
          </q-btn>
          <q-btn
            icon="icon-zoom-out"
            color="primary-500"
            class="q-pa-sm"
            @click="toolbarMethods.zoomOut"
          >
            <tooltip-pro style="z-index: 9999">Zoom Out</tooltip-pro>
          </q-btn>
          <q-btn
            icon="icon-scale-01"
            color="primary-500"
            class="q-pa-sm"
            @click="toolbarMethods.resize"
          >
            <tooltip-pro style="z-index: 9999">Reset Size</tooltip-pro>
          </q-btn>
          <q-btn
            icon="icon-download-02"
            color="primary-500"
            class="q-pa-sm"
            @click="
              $downloadItem(
                imgs[currentIndex]?.src,
                imgs[currentIndex]?.title
                  ? imgs[currentIndex]?.title?.includes?.('.')
                    ? imgs[currentIndex]?.title
                    : `${imgs[currentIndex]?.title || 'download'}.${imgs[
                        currentIndex
                      ]?.src
                        ?.split?.('.')
                        ?.pop?.()}`
                  : imgs[currentIndex]?.src?.split?.('/')?.pop?.()
              )
            "
          >
            <tooltip-pro style="z-index: 9999">Download</tooltip-pro>
          </q-btn>
          <q-btn
            icon="icon-refresh-ccw-01"
            color="primary-500"
            class="q-pa-sm"
            @click="toolbarMethods.rotateLeft"
          >
            <tooltip-pro style="z-index: 9999">Rotate Left</tooltip-pro>
          </q-btn>
          <q-btn
            icon="icon-refresh-cw-01"
            color="primary-500"
            class="q-pa-sm"
            @click="toolbarMethods.rotateRight"
          >
            <tooltip-pro style="z-index: 9999">Rotate Right</tooltip-pro>
          </q-btn>
        </q-btn-group>
      </div>
    </template>
  </vue-easy-lightbox>
</template>

<script>
import { defineComponent } from 'vue';
import VueEasyLightbox from 'vue-easy-lightbox';

export default defineComponent({
  name: 'ImageLightbox',

  components: { VueEasyLightbox },

  props: {
    imgs: {
      // https://onycat.com/vue-easy-lightbox/guide/#basic-usage-in-sfc
      // Img Url , string or Array of string
      // ImgObj { src: '', title: '', alt: '' }
      // 'src' is required
      // allow mixing
      // imgsRef.value = 'https://i.pravatar.cc/';
      // or
      // imgsRef.value  = {
      //   title: 'this is a placeholder',
      //   src: 'http://via.placeholder.com/350x150'
      // }
      // or
      // imgsRef.value = [
      //   'https://i.pravatar.cc/',
      //   'https://i.pravatar.cc/',
      //   'https://i.pravatar.cc/',
      // ];
      // or
      // imgsRef.value = [
      //   { title: 'test img', src: 'http://via.placeholder.com/350x150' },
      //   'http://via.placeholder.com/350x150'
      // ]
    },
    index: {
      type: Number,
      default: 0,
    },
    dark: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      currentIndex: 0,
    };
  },

  mounted() {
    if (this.index) this.currentIndex = this.index;
  },

  watch: {
    index: {
      async handler(val) {
        this.currentIndex = val;
      },
    },
  },

  methods: {
    indexChange(oldIndex, newIndex) {
      this.currentIndex = newIndex;
    },
  },
});
</script>
