<template>
  <q-avatar
    rounded
    class="rounded q-pa-sm auto-hw"
    :class="`bg-${bgColor} hover-bg-accent hover-text-secondary`"
  >
    <q-icon
      v-if="!useSpinner"
      :size="iconSize"
      :name="icon"
      :color="iconColor"
      class="card-icon q-pa-xs"
    />
    <q-spinner v-else :color="iconColor" :size="iconSize" />
  </q-avatar>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'CardIconBox',

  props: {
    icon: {
      type: String,
      default: 'icon-layout-alt-04',
    },
    bgColor: {
      type: String,
      default: 'accent',
    },
    iconSize: {
      type: String,
      default: 'sm',
    },
    iconColor: {
      type: String,
      default: 'secondary',
    },
    useSpinner: {
      type: Boolean,
      default: false,
    },
  },
});
</script>
