import { boot } from 'quasar/wrappers';
import useUser from 'stores/user';
import config from 'src/assets/_config';

// Auth
import fnStore from 'src/stores/fn.store';

export default boot(async ({ router, store }) => {
  const token = fnStore.getAuth();
  const { loginWithToken } = useUser(store);
  if (token) {
    // User has a session
    // Validate session
    const loginResponse = await loginWithToken();

    // Remove Save Token
    if (!loginResponse?.success) {
      fnStore.removeAuth();
      router.push('/auth');
    }
  }

  function checkPathPublicPattern(path) {
    for (const ptrn of config.publicPathPattern) {
      if (new RegExp(ptrn).test(path)) {
        return true;
      }
    }
    return false;
  }

  // This function will run everytime router path changes
  router.beforeEach((to, from, next) => {
    if (
      // Check if saved token exist
      // And going to /auth router path
      // Then forward to home page
      fnStore.getAuth() &&
      to.path === '/auth'
    ) {
      const new_route = {
        path: to.query?.redirect ? decodeURIComponent(to.query?.redirect) : '/',
      };
      next(new_route);
    } else if (
      // Check if saved token exist
      // And router path is not /auth
      // Also not a public path
      fnStore.getAuth() &&
      to.path !== '/auth' &&
      config.noLoginPath.includes(to.path) &&
      !(process.env.DEV_MODE && to.query.debugging)
    ) {
      const new_route = {
        path: to.query?.redirect ? decodeURIComponent(to.query?.redirect) : '/',
      };
      next(new_route);
    } else if (
      // Check if no saved token
      // And router path is not /auth
      // Also not a public path
      !fnStore.getAuth() &&
      to.path !== '/auth' &&
      !checkPathPublicPattern(to.path) &&
      !(process.env.DEV_MODE && to.query.debugging)
    ) {
      const pathUri = to.fullPath;
      const redirect = pathUri
        ? to.query?.redirect
          ? to.query?.redirect
          : pathUri
        : undefined;
      const new_route = {
        path: '/auth',
        query: {},
      };
      if (redirect) new_route.query['redirect'] = redirect;
      next(new_route);
    } else {
      // Else continue next
      next();
    }
  });
});

export {};
