import { register } from 'register-service-worker';
import { Dialog } from 'quasar';
import { displayNotification } from 'src/assets/scripts/notification';

// The ready(), registered(), cached(), updatefound() and updated()
// events passes a ServiceWorkerRegistration instance in their arguments.
// ServiceWorkerRegistration: https://developer.mozilla.org/en-US/docs/Web/API/ServiceWorkerRegistration

let updateChecker;
let autoReload = true;

register(process.env.SERVICE_WORKER_FILE, {
  // The registrationOptions object will be passed as the second argument
  // to ServiceWorkerContainer.register()
  // https://developer.mozilla.org/en-US/docs/Web/API/ServiceWorkerContainer/register#Parameter

  registrationOptions: {
    scope: './',
    // updateViaCache: 'none',
    type: 'classic',
  },

  async ready(registration) {
    let message = 'Service worker is active.';

    if (process.env.DEBUGGING) console.info(message);
    if ('periodicSync' in registration) {
      try {
        await registration.periodicSync.register('content-sync', {
          minInterval: 1000 * 60 * 60, // hourly sync
        });
      } catch (error) {
        if (process.env.DEBUGGING)
          console.error('Periodic background sync cannot be used.', error);
      }
    }
    if (process.env.DEBUGGING) console.info(message);
  },

  registered(registration) {
    let message = 'Service worker has been registered.';
    if (process.env.DEBUGGING) console.info(message);

    updateChecker = setInterval(() => {
      if (process.env.DEBUGGING) console.info('Checking for update.');
      registration.update();
    }, 1000 * 60 * 60); // hourly checks
  },

  cached(registration) {
    let message = 'Content has been cached for offline use.';

    if (process.env.DEBUGGING) console.info(message);
  },

  updatefound(registration) {
    let message = 'New content is downloading.';

    if (process.env.DEBUGGING) console.info(message);
  },

  updated(registration) {
    let title = 'App has been Updated!';
    let message = 'New content is available; please refresh.';
    if (updateChecker) clearInterval(updateChecker);
    if (autoReload) {
      window.location.href = window.location.href;
    } else {
      const { productName } = require('../package.json');
      displayNotification(productName + ' - ' + title, { body: message }).then(
        (notif) => {
          notif.onclick = () => {
            window.location.href = window.location.href;
          };
        }
      );
      Dialog.create({
        ok: {
          label: 'Refresh',
          rounded: true,
          noCaps: true,
          unelevated: true,
          size: 'md',
          color: 'primary',
          icon: 'icon-refresh-cw-01',
          class: 'font-normal rounded',
        },
        cancel: {
          label: '',
          flat: true,
          round: true,
          noCaps: true,
          unelevated: true,
          size: 'md',
          color: 'primary',
          icon: 'icon-x-close',
          class: 'absolute-top-right q-ma-sm',
        },
        seamless: true,
        noRouteDismiss: true,
        transitionHide: 'slide-down',
        transitionShow: 'slide-up',
        title,
        message,
        position: 'bottom',
        class: 'rounded q-mb-xl border-add q-pa-xs shadow-ui',
      }).onOk(() => {
        window.location.href = window.location.href;
      });
      if (process.env.DEBUGGING) console.info(message);
    }
  },

  offline() {
    let message = 'Using offline mode. Some features are not available!';

    Dialog.create({
      ok: false,
      cancel: {
        label: '',
        flat: true,
        round: true,
        noCaps: true,
        unelevated: true,
        size: 'md',
        color: 'primary',
        icon: 'icon-minus',
        class: 'absolute-top-right q-ma-sm hide',
      },
      seamless: true,
      noRouteDismiss: true,
      transitionHide: 'slide-up',
      transitionShow: 'slide-down',
      position: 'top',
      title: 'Offline Mode!',
      message,
      class: 'rounded border-add shadow-ui q-ma-md action-absolute',
    });
    if (process.env.DEBUGGING) console.info(message);
  },

  error(err) {
    let message = 'Error during service worker registration.';

    if (process.env.DEBUGGING) console.error(message + ':', err);
  },
});
