import { boot } from 'quasar/wrappers';
import { LoadingBar } from 'quasar';
import axios from 'axios';
import fnStore from 'src/stores/fn.store';
import config from 'src/assets/_config';
import JSZip from 'jszip';
import { base64 } from 'src/assets/scripts/functions';

const api = axios.create({ baseURL: config.api?.baseURL });
const cdn = config.cdn;
const API_UNAUTHORIZED = 300;

const downloadItem = async (url, label) => {
  LoadingBar.start();
  try {
    if (typeof url === 'number') {
      // Try folder id download
      const folder_id = url;
      url = `/folder/${folder_id}/download`;
    }
    api.get(url, { responseType: 'blob' }).then((response) => {
      const blob = new Blob([response.data]);
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = label;
      link.click();
      URL.revokeObjectURL(link.href);
    });
  } catch (e) {
    if (process.env.DEV_MODE) console.error(e);
  }
  LoadingBar.stop();
};

const downloadZip = async (urls, label) => {
  LoadingBar.start();
  try {
    if (process.env.DEV_MODE) console.log(`Downloading Zip:`, urls);
    const zip = new JSZip();
    if (!urls?.length) return;
    const promises = urls.map(({ url, folder }) =>
      fetch(url).then((res) => res.blob())
    );
    const files = await Promise.all(promises);
    files.forEach((file, index) => {
      let subFolders = zip;
      if (!urls[index].folder) urls[index].folder = '';
      urls[index].folder.split('/').forEach((folderName) => {
        subFolders = subFolders.folder(folderName);
      });
      subFolders.file(
        urls[index].filename || urls[index].url.split('/').pop(),
        file
      );
    });

    const content = await zip.generateAsync({ type: 'blob' });
    const link = document.createElement('a');

    link.href = URL.createObjectURL(content);
    link.download = `${label}${!label?.endsWith?.('.zip') ? '.zip' : ''}`;
    link.click();
  } catch (e) {
    if (process.env.DEV_MODE) console.error(e);
  }
  LoadingBar.stop();
};

export default boot(({ app, router }) => {
  /**
   * Intercept in API Request
   * Add Header Authorization, etc..
   * @param {*} request - XHR Request
   */
  const apiRequestHandler = (request) => {
    if (fnStore.getAuth())
      request.headers.Authorization = `Bearer ${fnStore.getAuth()}`;
    LoadingBar.start();
    return request;
  };

  /**
   * Intercept in API Responses
   * @param {*} response - XHR Response
   */
  const apiResponseHandler = (response) => {
    if (process.env.DEV_MODE) {
      console[response.status !== 200 || response.data?.code ? 'warn' : 'log'](
        'API Status:',
        response.status,
        (response.config?.method).toUpperCase(),
        '-',
        response.request?.responseURL,
        response
      );
    }
    if (response.status === 200) {
      if (
        response.config?.url === '/me' &&
        response.data?.code === API_UNAUTHORIZED
      ) {
        // Remove Save Token
        if (fnStore.getAuth()) fnStore.removeAuth();
        if (router.currentRoute?.value?.path !== '/auth') {
          // Hard Refresh
          router.push('/auth');
          window.location.href = '/auth';
        }
      }
    }
    LoadingBar.stop();
    return response;
  };

  /**
   * Intercept in API Error Responses
   * @param {*} error - XHR/Error Response
   */
  const apiErrorHandler = (error) => {
    if (process.env.DEV_MODE) console.error('API Error:', error);

    LoadingBar.stop();
    return Promise.reject(error);
  };

  // Intercept all api request
  api.interceptors.request.use(
    (request) => apiRequestHandler(request),
    ({ error, config, code, request, response }) =>
      apiErrorHandler({ error, config, code, request, response })
  );

  // Intercept all api response
  api.interceptors.response.use(
    (response) => apiResponseHandler(response),
    ({ error, config, code, request, response }) =>
      apiErrorHandler({ error, config, code, request, response })
  );

  app.config.globalProperties.$downloadItem = downloadItem; // this.$downloadItem
  app.config.globalProperties.$downloadZip = downloadZip; // this.$downloadZip

  // for use inside Vue files (Options API)
  // API and Axios
  app.config.globalProperties.$axios = axios; // this.$axios
  app.config.globalProperties.$api = api; // this.$api
  // CDN
  app.config.globalProperties.$cdn_host = cdn.baseURL; // this.$cdn_host
  app.config.globalProperties.$cdn = cdn; // this.$cdn
});

export { axios, api, cdn, API_UNAUTHORIZED, downloadItem, downloadZip };
