const routes = [
  {
    path: '/',
    component: () => import('layouts/MainLayout.vue'),
    children: [
      { path: '', component: () => import('src/pages/IndexPage.vue') },
      {
        path: 'requests',
        component: () => import('src/pages/RequestsPage.vue'),
      },
      {
        path: 'brands',
        children: [
          {
            path: '',
            component: () => import('src/pages/Brands/BrandsPage.vue'),
          },
          {
            path: ':brandId',
            children: [
              {
                path: '',
                component: () => import('src/pages/Brands/BrandOverview.vue'),
              },
              {
                path: 'task',
                children: [
                  {
                    path: ':taskId',
                    component: () =>
                      import('src/pages/Brands/TaskOverview.vue'),
                  },
                ],
              },
              {
                path: 'project',
                children: [
                  {
                    path: ':projectId',
                    component: () =>
                      import('src/pages/Brands/ProjectOverview.vue'),
                  },
                ],
              },
            ],
          },
        ],
      },
      { path: 'files', component: () => import('src/pages/FilesPage.vue') },
      { path: 'help', component: () => import('src/pages/HelpCenterPage.vue') },
      {
        path: 'discover',
        component: () => import('src/pages/DiscoverPage.vue'),
      },
      { path: 'gift', component: () => import('src/pages/GiftPage.vue') },
      {
        path: 'book-a-call',
        component: () => import('src/pages/BookACallPage.vue'),
      },
      {
        path: 'settings',
        children: [
          {
            path: '',
            component: () => import('src/pages/AccountSettingsPage.vue'),
          },
          {
            path: ':settings_tab',
            component: () => import('src/pages/AccountSettingsPage.vue'),
          },
        ],
      },
    ],
  },
  {
    path: '/request',
    children: [
      {
        path: 'task/:taskId',
        component: () => import('src/pages/Request/RequestForm.vue'),
      },
      {
        path: 'project/:projectId',
        component: () => import('src/pages/Request/RequestForm.vue'),
      },
    ],
  },
  {
    path: '/quick-request/:quickRequestType',
    component: () => import('src/pages/Request/QuickRequestForm.vue'),
  },
  {
    path: '/signup',
    children: [
      {
        path: '',
        component: () => import('src/pages/Misc/SignupPage.vue'),
      },
      {
        path: 'verify/:signup_token',
        component: () => import('src/pages/Misc/SignupPage.vue'),
      },
    ],
  },
  {
    path: '/invitation',
    children: [
      {
        path: ':invite_token',
        component: () => import('src/pages/Misc/SignupPage.vue'),
      },
    ],
  },
  {
    path: '/checkout',
    // component: () => import('src/pages/Misc/CheckoutPage-1.vue'),
    children: [
      {
        path: ':status',
        component: () => import('src/pages/Misc/CheckoutPage.vue'),
      },
    ],
  },
  {
    path: '/auth',
    component: () => import('layouts/EmptyLayout.vue'),
    children: [
      {
        path: '',
        component: () => import('src/pages/Misc/AuthenticationPage.vue'),
      },
    ],
  },

  // Error Handlers
  {
    path: '/:catchAll(.*)*',
    component: () => import('src/pages/Misc/_Error_NotFound.vue'),
  },
];

export default routes;
