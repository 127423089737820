<template>
  <router-view v-slot="{ Component }">
    <component :is="Component" />
  </router-view>

  <!-- Request Option (Task/Project) -->
  <q-dialog
    full-width
    full-height
    :maximized="false"
    v-model="$root.request.new"
    transition-show="fade"
    transition-hide="fade"
    :transition-duration="300"
  >
    <RequestOptions
      class="rounded"
      :noclose="false"
      :style="`max-width:850px !important; max-height: 690px !important;`"
    />
  </q-dialog>

  <!-- Task Directory -->
  <q-dialog
    full-width
    full-height
    v-model="$root.request.task"
    transition-show="fade"
    transition-hide="fade"
    :transition-duration="300"
  >
    <DirectoryLayout
      class="rounded"
      title="Task Directory"
      directoryType="tasks"
      :style="`max-width: ${$q.screen.sizes.lg}px !important; max-height: 900px !important;`"
    />
  </q-dialog>

  <!-- Project Directory -->
  <q-dialog
    full-width
    full-height
    v-model="$root.request.project"
    transition-show="fade"
    transition-hide="fade"
    :transition-duration="300"
  >
    <DirectoryLayout
      class="rounded"
      title="Project Directory"
      directoryType="projects"
      :style="`max-width: ${$q.screen.sizes.lg}px !important; max-height: 900px !important;`"
    />
  </q-dialog>

  <!-- Offline Notice -->
  <q-dialog v-model="showOfflineMessage" seamless position="top">
    <q-card flat class="rounded shadow-ui q-ma-xs q-px-sm q-py-xs bg-warning">
      <div class="flex flex-center text-overline">
        Offline mode. Trying to reconnect.
        <q-spinner-radio size="xs" :thickness="1" class="q-pl-sm self-end" />
      </div>
    </q-card>
  </q-dialog>

  <!-- Update API Server -->
  <q-dialog
    v-model="dialogUpdateServer"
    :transition-duration="500"
    transition-show="flip-up"
    transition-hide="flip-down"
  >
    <Form_ServerConfig
      @success="serverSettingResponse"
      @failed="serverSettingResponse"
      @close="dialogUpdateServer = false"
    />
  </q-dialog>

  <!-- App Version -->
  <div
    class="row flex-center fixed-bottom-right q-mb-sm q-mr-sm"
    style="z-index: 999999"
  >
    <div class="text-overline text-primary-400">
      {{ $config.pkjson?.productName }} v{{ $config.pkjson?.version }}
    </div>
  </div>
</template>

<script>
import { ref, defineComponent, defineAsyncComponent } from 'vue';
import GrowmodoIconSets from 'src/assets/fonts/icons/iconSets';
import config from 'src/assets/_config';
import { useMeta, useQuasar, date } from 'quasar';
import { tryClearInterval } from 'src/assets/scripts/functions';
import { mapActions, mapState } from 'pinia';
import useHelpers from 'stores/helpers';
import useUser from 'stores/user';
import fnStore from './stores/fn.store';
import { GeoPermission_Mixin } from 'src/mixins';

export default defineComponent({
  name: 'App',

  mixins: [GeoPermission_Mixin],

  components: {
    RequestOptions: defineAsyncComponent(() =>
      import('src/components/Requests/Request_Options.vue')
    ),
    DirectoryLayout: defineAsyncComponent(() =>
      import('src/components/Requests/DirectoryLayout.vue')
    ),
    Form_ServerConfig: defineAsyncComponent(() =>
      import('src/components/Forms/Form_ServerConfig.vue')
    ),
  },

  data() {
    return {
      dark: true,
      config,
      debugging: process.env.DEV_MODE,
      mainDrawer: false,
      taskDirectoryDrawer: true,
      mainDrawerCfg: {
        mini: 64,
        width: 208,
      },
      settingsDrawer: false,
      request: {
        new: false,
        task: false,
        project: false,
      },
      task: {
        new: false,
      },
      project: {
        new: false,
      },
      redirect: {
        confirmation: false,
      },
      upgradePlan: false,
      dialogRequestUpgrade: false,
      showOfflineMessage: false,
      open_server_settings: {
        key: '\\',
        times: 3,
        press_times: 0,
        last_press: 0,
      },
      dialogUpdateServer: false,
    };
  },

  setup() {
    const $q = useQuasar();
    const sessionDuration = ref(0);
    const helper = useHelpers();
    let checking_connection = ref(false);

    // Update Icon Set
    $q.iconSet.set(GrowmodoIconSets);

    $q.iconMapFn = (iconName) => {
      if (iconName.startsWith('icon-') === true) {
        // Growmodo Untitled Icons
        return {
          cls: iconName,
        };
      }
    };

    // Create Realtime Date
    let realtimeDate = ref(new Date());
    let intervalSec = setInterval(async () => {
      sessionDuration.value += 1;
      if (sessionDuration.value % 10 === 0) {
        // Check for Connectivity every 10 seconds
        checking_connection.value = true;
        if (!checking_connection.value) {
          helper.checkIfOnline().finally(() => {
            checking_connection.value = false;
          });
        }
      }
      realtimeDate.value = new Date();
    }, 1000);

    // Meta
    const meta = ref(config.metaData);
    useMeta(() => {
      let data = {};
      Object.keys(meta.value).forEach((d) => {
        data[d] = meta.value[d];
      });
      return data;
    });

    async function setMeta(data) {
      Object.keys(data).forEach((d) => {
        meta.value[d] = data[d];
      });
    }

    function getMeta(key) {
      return key ? meta.value[key] : meta;
    }

    return {
      setMeta,
      getMeta,
      realtimeDate,
      intervalSec,
      sessionDuration,
      checkIfOnline: helper.checkIfOnline,
    };
  },

  computed: {
    ...mapState(useHelpers, [
      'isOnline',
      'greetingsMessage',
      'messagesDialogs',
    ]),
    ...mapState(useUser, ['activeOrgID', 'selectedOrgIsCancelled']),

    selectedOrgIsExpired() {
      return this.activeOrgID
        ? this.subscriptionIsExpired(this.realtimeDate)
        : false;
    },
  },

  watch: {
    ['$q.appVisible']: {
      async handler(val) {
        if (process.env.DEV_MODE) {
          console.log(
            val ? 'App became visible' : 'App went in the background'
          );
        }
      },
    },

    ['isOnline']: {
      async handler(val) {
        if (process.env.DEV_MODE) {
          console.log(val ? 'Connection is online' : 'Connection is offline');
        }
        this.showOfflineMessage = !val;
      },
    },

    selectedOrgIsCancelled: {
      async handler(val) {
        if (val) {
          // Logout User if Organization is Cancelled
          if (fnStore.getAuth()) this.logoutAccount();
        }
      },
    },

    selectedOrgIsExpired: {
      async handler(val) {
        if (val) {
          // Logout User if Organization is Cancelled
          if (fnStore.getAuth()) this.logoutAccount();
        }
      },
    },
  },

  methods: {
    ...mapActions(useUser, ['logout', 'subscriptionIsExpired']),

    goTo_Home() {
      // Navigate to Home
      this.$router.push('/');
    },

    logoutAccount(path = '/auth') {
      this.logout(path);
      this.$router.replace(path);
    },

    getItemCreatePath(itemType, itemInfo) {
      return `/request/${itemType}/${itemInfo.id}`;
    },

    requestAction(itemType, itemInfo) {
      const path = this.getItemCreatePath(itemType, itemInfo);
      if (process.env.DEV_MODE)
        console.info(`Requesting ${itemType}:`, itemInfo, path);
      this.$router.push(path);
      return path;
    },

    toggleMainDrawer(forceValue = null) {
      if (this.mainDrawer === undefined) return;
      if (forceValue !== null && typeof forceValue === 'boolean')
        this.mainDrawer = forceValue;
      else this.mainDrawer = !this.mainDrawer;
      return;
    },

    toggleTaskDirectoryDrawer(forceValue = null) {
      if (this.taskDirectoryDrawer === undefined) return;
      if (forceValue !== null && typeof forceValue === 'boolean')
        this.taskDirectoryDrawer = forceValue;
      else this.taskDirectoryDrawer = !this.taskDirectoryDrawer;
      return;
    },

    toggleSettingsDrawer(forceValue = null) {
      if (this.settingsDrawer === undefined) return;
      if (forceValue !== null && typeof forceValue === 'boolean')
        this.settingsDrawer = forceValue;
      else this.settingsDrawer = !this.settingsDrawer;
      return;
    },

    toggleRedirectConfirmation(redirectInfo, forceValue = null) {
      let res = this.toggleType('redirect', 'confirmation', forceValue);
      if (redirectInfo)
        this.redirect = {
          confirmation: res,
          ...redirectInfo,
        };
    },

    toggleRequest(type, value = null) {
      this.toggleType('request', type, value);
    },

    toggleType(_var, type, forceValue = null) {
      let res;
      if (!type) {
        if (forceValue !== null && typeof forceValue === 'boolean')
          res = forceValue;
        else res = !this?.[_var]?.new;
        this[_var].new = res;
      } else {
        if (this[_var][type] === undefined) return;
        // Open Request Option First
        // this[_var].new = true;
        if (forceValue !== null && typeof forceValue === 'boolean')
          res = forceValue;
        else res = !this?.[_var]?.[type];
        this[_var][type] = res;
      }
      return res;
    },

    refresh(timeout = 1000) {
      setTimeout(() => {
        window.location.href = window.location.href;
      }, timeout);
    },

    /**
     * Handle Some Keys
     *
     * @param {KeyboardEvent} evt
     */
    async handleKeyDown(evt) {
      let prevent = false;
      const block_fkeys = [
        'F1',
        'F2',
        'F3',
        'F4',
        'F5',
        'F6',
        'F7',
        'F8',
        'F9',
        'F10',
        'F11',
        'F12',
      ];
      if (!prevent && block_fkeys.indexOf(evt.key) !== -1) prevent = true;
      const block_console = () => {
        // Block F* Keys
        if (evt.shiftKey && evt.ctrlKey) prevent = true;
        if (prevent && process.env.DEV_MODE) {
          prevent = false;
          console.info('DevTools is allowed for Debugging');
        }
      };
      if (!prevent && ['i', 'j', 'c'].indexOf(evt.key.toLowerCase()) !== -1) {
        // Block Console
        block_console();
      } else if (!prevent && evt.key.toLowerCase() == 'r' && evt.ctrlKey) {
        // Block Refresh
        prevent = true;
      } else if (!prevent && evt.key == this.open_server_settings.key) {
        // Server Configuration Magic Key
        if (!this.open_server_settings.press_times)
          this.open_server_settings.last_press = evt.timeStamp;
        const diff = date.getDateDiff(
          evt.timeStamp,
          this.open_server_settings.last_press,
          'seconds'
        );
        if (diff >= 0 && diff < 2) {
          if (
            this.open_server_settings.press_times !=
            this.open_server_settings.times - 1
          ) {
            // no shift and control
            if (!evt.ctrlKey && !evt.shiftKey)
              this.open_server_settings.press_times += 1;
          } else {
            // last should press control
            if (evt.ctrlKey) this.open_server_settings.press_times += 1;
          }
          if (
            this.open_server_settings.press_times ==
            this.open_server_settings.times
          ) {
            // Open Server Settings
            this.dialogUpdateServer = true;
            // Reset Counter
            this.open_server_settings.press_times = 0;
            // Prevent Default Action
            prevent = true;
          }
          // Reset Delay
          setTimeout(() => {
            if (this.open_server_settings.press_times) {
              this.open_server_settings.press_times = 0;
              this.open_server_settings.last_press = evt.timeStamp;
            }
          }, 5000);
        }
      }
      if (prevent) evt.preventDefault();
    },

    serverSettingResponse(response, server) {
      if (response.success) {
        this.refresh(2000);
        this.$q.notify({
          position: 'top',
          icon: 'icon-check-circle-broken',
          message: response?.message || 'Server Connected',
        });
      } else {
        this.$q.notify({
          position: 'top',
          icon: 'icon-alert-triangle',
          iconColor: 'negative',
          message: response?.message || 'Server connection failed',
        });
      }
    },
  },

  created() {
    this.getIpInformation();
    this.showOfflineMessage = !this.isOnline;
    window.addEventListener('offline', this.checkIfOnline);
    window.addEventListener('online', this.checkIfOnline);
    window.addEventListener('keydown', this.handleKeyDown, true);
    if (this.selectedOrgIsCancelled && fnStore.getAuth()) this.logoutAccount();
    if (this.selectedOrgIsExpired && fnStore.getAuth()) this.logoutAccount();
  },

  beforeUnmount() {
    tryClearInterval(this.intervalSec);
    window.removeEventListener('offline', this.checkIfOnline);
    window.removeEventListener('online', this.checkIfOnline);
    window.removeEventListener('keydown', this.handleKeyDown, true);
  },
});
</script>
